
(function($, App) {
    // header panel transformations
    if (!$('#header-center').length) {
        return;
    }

    if ($('body.fixed-header').length) {
        return;
    }

    if ($('.mobile-layout').length) {
        return;
    }
    var header = $('#page-header');
    var tresholdHeight = header.height();
    var shiftHeight = header.find('.header-top').height() || 0;
    var body = $('body');
    var scroller = new Scroller($('body'), $('#header-center'));
    scroller.addCallback('getStart', function(){
        return tresholdHeight;
    });
    scroller.addCallback('doStart', function(){
        body.removeClass('fixed-header');
        header.css('top', '-' + (tresholdHeight + 50) + 'px');
        body.css('padding-top', '0');
        scroller.moving = false;
    });
    scroller.addCallback('doMove', function(){
        if (!scroller.moving) {
            body.addClass('fixed-header');
            body.css('padding-top', tresholdHeight + 'px');
            header.css('top', (-1 * shiftHeight) + 'px');
            scroller.moving = true;
        }
    });
    scroller.addCallback('doStop', function(){});
    scroller.run(App.ScrollerManager);

})(jQuery, App);

(function($) {
    // user menu popups
    $('#user-menu .need-popup').popup({
        lastResort: true,
        position   : 'bottom right',
        maxSearchDepth: 0, // not care fit or not, just display
        on: 'click',
        onShow: function(elem) {
            $(elem).addClass('hover');
        },
        onHide: function(elem) {
            $(elem).removeClass('hover');
        }
    });
})(jQuery, App);


(function($) {

    var menuButton = $('#main-menu-button');
    if (!menuButton.length) {
        return;
    }

    var opened = false;
    var menuBox = $('#main-menu-box');
    var menuSubboxes = $('#main-menu-box .right-subbox');
    var mainMenuItems = $('#main-menu .item');
    var openedSubbox = '';
    var holdSubbox = false;

    menuButton.on('click', function(event) {
        if (opened) {
            menuButton.removeClass('opened');
            menuBox.addClass('invisible');
        } else {
            menuButton.addClass('opened');
            menuBox.removeClass('invisible');
        }
        opened = !opened;
    });

    menuSubboxes.on('mouseenter', function(event) {
        if (!holdSubbox) {
            holdSubbox = $(event.currentTarget).prop('id');
        }
    });

    mainMenuItems.on('mouseenter', function(event) {
        var item = $(event.currentTarget);
        if (item.hasClass('sub')) {
            if (openedSubbox) {
                // delay
                holdSubbox = false;
                setTimeout(function () {
                    if (holdSubbox != openedSubbox) {
                        // close all subbox and open if need
                        mainMenuItems.removeClass('active');
                        item.addClass('active');
                        menuSubboxes.addClass('invisible');
                        if (item.hasClass('need-subbox')) {
                            var id = item.data('subbox');
                            $('#' + id).removeClass('invisible');
                            openedSubbox = id;
                        }
                    }
                }, 200);
            } else {
                mainMenuItems.removeClass('active');
                item.addClass('active');
                if (item.hasClass('need-subbox')) {
                    var id = item.data('subbox');
                    $('#' + id).removeClass('invisible');
                    openedSubbox = id;
                }
            }
        }
    });

    mainMenuItems.on('click', function(event) {

        var item = $(event.currentTarget);
        if (item.hasClass('sub')) {
            mainMenuItems.removeClass('active');
            item.addClass('active');
            menuSubboxes.addClass('invisible');
            if (item.hasClass('need-subbox')) {
                event.preventDefault();
                var id = item.data('subbox');
                $('#' + id).removeClass('invisible');
                openedSubbox = id;
            }
        }
    });

    menuBox.on('mouseleave', function(event) {
        menuButton.removeClass('opened');
        menuBox.addClass('invisible');
        opened = false;
    });

})(jQuery);


(function($) {
    // company menu dropdown
    $('#company-navbar.need-dropdown').dropdown({
        // you can use any ui transition
        transition: 'drop',
        onShow: function(e) {
            $(this).find('.button.dropdown').addClass('active');
        },
        onHide: function(e) {
            $(this).find('.button.dropdown').removeClass('active');
        }
    });
})(jQuery, App);

(function (App, $) {
    var tabs = $('.product-descriptions > .tab-menu-panel');
    var imagesLoaded = require('imagesloaded');

    if (tabs.length) {
        $('.tab-menu-panel-header .switch-tab').on('click', function (event) {
            var button = $(event.currentTarget);
            if (button.hasClass('active')) {
                return false;
            }
            $('.tab-menu-panel-header .switch-tab').removeClass('active');
            $('.tab-menu-panel-contents .tab-content').addClass('invisible');
            var id = button.data('tab-content');
            button.addClass('active');
            $(id).removeClass('invisible');
        });
        $('.tab-menu-panel-header .switch-tab').first().click();
    }

    imagesLoaded('#accordion-block', function() {
        var accordion = $('.product-descriptions > .accordion');
        var accordionTitles = accordion.find('.accordion-title');
        var accordionContents = accordion.find('.accordion-content');
        if (accordion.length) {
            accordionContents.each(function (i, element) {
                $(element).data('height', $(element).outerHeight()).css('height', '0px');
            });
            accordionTitles.on('click', function (event) {
                var title = $(this);
                if (!title.hasClass('active')) {
                    var prevTitle = accordion.find('.accordion-title.active');
                    var content = title.addClass('active').next();
                    content.addClass('active').css('height', content.data('height'));
                    if (prevTitle.length) {
                        prevContent = prevTitle.removeClass('active').next();
                        prevContent.css('height', '0px').removeClass('active');
                    }
                }
            });
            accordion.find('.accordion-title').first().click();
        }
    });


})(App, jQuery);

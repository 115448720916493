// sidebar box rail
(function ($,App) {

    var rail = $('#checkout-summary-rail');
    var train = $('#checkout-summary-train');

    if ( (0 < rail.length) && (0 < train.length) ) {
        var scroller = new Scroller(rail, train);
        scroller.addCallback('getStop', function () {
            return ((scroller.rail.outerHeight() + scroller.railOffset.top) - scroller.train.outerHeight() ) - scroller.preStop;
        });
        scroller.run(App.ScrollerManager);
    }

})(jQuery, App);

// sidebar button enable / disable
(function ($,App) {

    if ($('body').hasClass('page-checkout-summary')) {
        $('#gtc_accept').on('change', function(event){
            var cb = $(event.currentTarget);
            if (cb.prop('checked')) {
                $('#cart-goto-next-step-button').prop('disabled', false);
            } else {
                $('#cart-goto-next-step-button').prop('disabled', true);
            }
        });
        $('#cart-goto-next-step-button').prop('disabled', true);
    }

})(jQuery, App);

(function (App, $) {
    if (!$('#banners-home-slider').length) {
        return;
    }

    function HomeSlider() {
        this.box = $('#banners-home-slider');
        this.controls = this.box.find('.control-panel');
        this.autoplay = null;
        this.autoplayInterval = 13000;
    }

    HomeSlider.prototype.init = function () {
        this.controls.find('.direct-buttons .button').on('click', function (event) {
            var button = $(event.currentTarget);
            var id = button.data('id');
            homeSlider.controls.find('.direct-buttons .button').removeClass('active');
            button.addClass('active');
            if (homeSlider.box.find('.banner.visible').length) {
                homeSlider.box.find('.banner.visible').transition({
                    animation: 'fade out',
                    duration: '2s'
                });
            }

            var current = homeSlider.box.find(id);
            if (current.length) {
                var flyIns = current.find('.fly-in').removeClass('visible').addClass('hidden transition');
                if (typeof current.transition === 'function') {
                    current.transition({
                        animation: 'fade in',
                        duration: '2s',
                        onComplete: function () {
                            flyIns.each(function () {
                                var item = $(this);
                                var transition = item.data('transition');
                                item.transition(transition, '1000ms');
                            });
                        }
                    });
                }
            }
        });

        this.controls.find('.next-button').on('click', function (event) {
            var button = $(event.currentTarget);
            var next, found;
            homeSlider.controls.find('.direct-buttons .button').each(function (i, banner) {
                if (undefined != found) {
                    next = $(banner);
                    return false;
                }
                if ($(banner).hasClass('active')) {
                    found = i;
                }
            });
            if (next) {
                next.click();
            } else {
                homeSlider.controls.find('.direct-buttons .button').first().click();
            }
        });

        this.controls.find('.previous-button').on('click', function (event) {
            var button = $(event.currentTarget);
            var previous, found;
            homeSlider.controls.find('.direct-buttons .button').each(function (i, banner) {
                if ($(banner).hasClass('active')) {
                    found = i;
                    return false;
                }
                if (undefined == found) {
                    previous = $(banner);
                }
            });
            if (previous) {
                previous.click();
            } else {
                homeSlider.controls.find('.direct-buttons .button').last().click();
            }
        });

        this.box.on('swiperight', function (event) {
            homeSlider.controls.find('.previous-button').click();
        }).on('swipeleft', function (event) {
            homeSlider.controls.find('.next-button').click();
        });

        this.box.on('mouseenter', function (event) {
            homeSlider.autoplayStop();
        }).on('mouseleave', function (event) {
            homeSlider.autoplayStart();
        });

    };

    HomeSlider.prototype.autoplayStart = function () {
        if (this.autoplay) {
            this.autoplayStop();
        }
        this.autoplay = setInterval(function () {
            homeSlider.controls.find('.next-button').click();
        }, this.autoplayInterval);
    };

    HomeSlider.prototype.autoplayStop = function () {
        clearInterval(this.autoplay);
    };

    var homeSlider = new HomeSlider();
    homeSlider.init();
    homeSlider.autoplayStart();

})(App, jQuery);

(function (App, $) {
    function BottomPopup() {
        this.popup = $('#banners-bottom-popup');
    }

    var addDays = function (actualDate, days) {
        var date = new Date(actualDate.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    };

    BottomPopup.prototype.init = function () {
        if (!this.popup.length) {
            return;
        }

        var self = this,
            dismissed = null,
            dismissTimeout = parseInt(this.popup.data('dismiss-timeout'));

        if (App.Storage.get('bottom-popup-' + this.popup.data('id'))) {
            dismissed = App.Storage.get('bottom-popup-' + this.popup.data('id'));

            if (typeof dismissed === 'string') {
                // datetime string
                dismissed = new Date(dismissed);
            }

            // bc compatibility
            if (typeof dismissed === 'boolean') {
                dismissed = new Date();
            }
        }

        if ((!dismissTimeout && dismissed === null) || (dismissTimeout && dismissed - new Date() <= 0)) {
            $('body').addClass('scrolling dimmable dimmed');
            self.popup.removeClass('invisible');
            window.setTimeout(function () {
                self.popup.find('.wrapper').addClass('show');
            }, 500);
        }

        this.popup.find('button.cancel-button').click(function () {
            self.popup.find('.wrapper').removeClass('show');
            $('body').removeClass('scrolling dimmable dimmed');
            self.popup.addClass('invisible');

            App.Storage.set('bottom-popup-' + self.popup.data('id'), addDays(new Date(), dismissTimeout));
        });

        this.popup.find('a.cab').click(function () {
            self.popup.find('.wrapper').removeClass('show');
            $('body').removeClass('scrolling dimmable dimmed');
            self.popup.addClass('invisible');

            App.Storage.set('bottom-popup-' + self.popup.data('id'), addDays(new Date(), dismissTimeout));
        });
    };

    var bottomPopup = new BottomPopup();
    $(function () {
        bottomPopup.init();
    });
})(App, jQuery);

(function($, App) {

    var countdowns = $('.countdown');

    if (!countdowns.length) {
        return;
    }

    function start(countdown) {
        var end = countdown.data('countdown-end');
        if (end) {
            var nowDate = new Date();
            var now = nowDate.getTime();
            // countdown.cd_remain = Date.parse(end) - Date.parse(new Date());
            countdown.cd_remain = Date.parse(end.replace(' ', 'T')) - now;
            if (0 < countdown.cd_remain) {
                loop(countdown);
            }
        }
    }

    function end(countdown) {
        display(countdown, {'total': 0, 'days': 0, 'hours': 0, 'minutes': 0, 'seconds': 0});
    }

    function normalize(time) {
        var seconds = Math.floor((time / 1000) % 60);
        var minutes = Math.floor((time / 1000 / 60) % 60);
        var hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        var days = Math.floor(time / (1000 * 60 * 60 * 24));
        return {'total': time, 'days': days, 'hours': hours, 'minutes': minutes, 'seconds': seconds};
    }

    function display(countdown, times) {
        if (undefined !== times.seconds) {
            times.seconds = ('0' + times.seconds).slice(-2);
            countdown.find('.seconds').each(function(i,elem){
                $(elem).text(times.seconds[i]);
            });
        }
        if (undefined !== times.minutes) {
            times.minutes = ('0' + times.minutes).slice(-2);
            countdown.find('.minutes').each(function(i,elem){
                $(elem).text(times.minutes[i]);
            });
        }
        if (undefined !== times.hours) {
            times.hours = ('0' + times.hours).slice(-2);
            countdown.find('.hours').each(function(i,elem){
                $(elem).text(times.hours[i]);
            });
        }
        if (undefined !== times.days) {
            times.days = ('0' + times.days).slice(-2);
            countdown.find('.days').each(function(i,elem){
                $(elem).text(times.days[i]);
            });
        }
    }
    function animate(countdown, fullUpdate) {
        fullUpdate = fullUpdate || false;
        var times = normalize(countdown.cd_remain);
        var changed = {
            seconds: times.seconds
        };
        if (59 == times.seconds || fullUpdate) {
            changed.minutes = times.minutes
        }
        if (59 == times.minutes || fullUpdate) {
            changed.hours = times.hours
        }
        if (23 == times.hours || fullUpdate) {
            changed.days = times.days
        }
        display(countdown, changed);
    }

    function loop(countdown) {
        countdown.firstRun = true;
        countdown.cd_loop = setInterval(
            function() {
                countdown.cd_remain = countdown.cd_remain - 1000;
                if (0 >= countdown.cd_remain) {
                    clearInterval(countdown.cd_loop);
                    end(countdown);
                } else {
                    if (countdown.firstRun) {
                        countdown.firstRun = false;
                        animate(countdown, true);
                    } else {
                        animate(countdown);
                    }
                }
            }, 1000
        );
    }

    countdowns.each(function() {
        start($(this));
    });

})(jQuery, App);

(function ($, App) {
    'use strict';

    function Alcar() {
        this.apiUrl = '/user-cars/alcar-api';
        this.brands = [];
        this.types = {};
        this.models = {};
        this.alcarProducts = {};
    }

    Alcar.prototype.getBrands = function (callback) {
        if (this.brands.length) {
            callback(true, this.brands);
            return;
        }

        var self = this,
            url = this.apiUrl + '?nextFilters=brands';
        $.ajax({
            type: 'GET',
            url: url,
            data: {},
            success: function (data) {
                self.brands = data;
                callback(true, data);
            },
            error: function (data) {
                callback(false, data);
            }
        });
    };

    Alcar.prototype.getTypes = function (brandCode, callback) {
        if (!brandCode) {
            callback(false, null);
            return;
        }

        if (typeof this.types[brandCode] !== 'undefined') {
            callback(true, this.types[brandCode]);
            return;
        }

        var self = this,
            url = this.apiUrl + '?nextFilter=types&filters[brandcode]=' + brandCode;
        $.ajax({
            type: 'GET',
            url: url,
            data: {},
            success: function (data) {
                self.types[brandCode] = data;
                callback(true, data);
            },
            error: function (data) {
                callback(false, data);
            }
        });
    };

    Alcar.prototype.getModels = function (brandCode, typeCode, callback) {
        if (!brandCode || !typeCode) {
            callback(false, null);
            return;
        }

        if (typeof this.models[brandCode + '_' + typeCode] !== 'undefined') {
            callback(true, this.models[brandCode + '_' + typeCode]);
            return;
        }

        var self = this,
            url = this.apiUrl + '?nextFilter=models&filters[brandcode]=' + brandCode + '&filters[typecode]=' + typeCode;
        $.ajax({
            type: 'GET',
            url: url,
            data: {},
            success: function (data) {
                self.models[brandCode + '_' + typeCode] = data;
                callback(true, data);
            },
            error: function (data) {
                callback(false, data);
            }
        });
    };

    Alcar.prototype.getAlcarProducts = function (modelCode, callback) {
        if (!modelCode) {
            callback(false, null);
            return;
        }

        if (typeof this.alcarProducts[modelCode] !== 'undefined') {
            callback(true, this.alcarProducts[modelCode]);
            return;
        }

        var self = this,
            url = this.apiUrl + '?nextFilter=sizes&filters[modelcode]=' + modelCode;
        $.ajax({
            type: 'GET',
            url: url,
            data: {},
            success: function (data) {
                self.alcarProducts[modelCode] = data;
                callback(true, data);
            },
            error: function (data) {
                callback(false, data);
            }
        });
    };

    Alcar.prototype.getModelRimPriority = function (model) {
        if ('DOTS' == model.Brand && 'Hanzo' == model.Design && 'Black matt/polished' == model.ColourText) {
            return 100;
        }
        if ('AEZ' == model.Brand && 'Reef' == model.Design.substr(0, 4) && 'Black matt/polished' == model.ColourText) {
            return 100;
        }
        if ('DEZENT' == model.Brand && ('TD' == model.Design || 'TH' == model.Design) && 'Silver' == model.ColourText) {
            return 70;
        }
        if ('ENZO' == model.Brand && ('B' == model.Design || 'G' == model.Design) && 'Silver' == model.ColourText) {
            return 60;
        }
        if ('DEZENT' == model.Brand && (-1 < ['RE', 'TG', 'TX', 'RB'].indexOf(model.Design)) && 'Silver' == model.ColourText) {
            return 50;
        }
        if ('Silver' == model.ColourText) {
            return 10;
        }
        return 1;
    };

    Alcar.prototype.getMostBeautifulRimModel = function (alcarProducts) {
        var lastPriority = 0;
        var model = {};
        var keys = Object.keys(alcarProducts);
        for (var i = 0, len = keys.length; i < len; ++i) {
            var item = alcarProducts[keys[i]];
            var priority = this.getModelRimPriority(item);
            if (priority > lastPriority) {
                lastPriority = priority;
                model = item;
            }
            if (99 < lastPriority) {
                break;
            }
        }
        return model;
    };

    Alcar.prototype.checkTpms = function (alcarProduct) {
        if (alcarProduct.TPMSLine) {
            for (var i = 0, len = alcarProduct.TPMSLine.length; i < len; ++i) {
                if ('1' == alcarProduct.TPMSLine[i].isOE) {
                    return true;
                }
            }
        }
        return false;
    };

    Alcar.prototype.getRimSizes = function (alcarProducts) {
        var sizes = [];
        var keys = Object.keys(alcarProducts);
        for (var i = 0, len = keys.length; i < len; ++i) {
            var item = alcarProducts[keys[i]];
            if (-1 === sizes.indexOf(item.diameter)) {
                sizes.push(item.diameter);
            }
        }
        sizes.sort();
        return sizes;
    };

    Alcar.prototype.getTyreSizes = function (alcarProducts, mostUsed) {
        mostUsed = mostUsed || false;
        var sizes = [];
        var size = '';
        var keys = Object.keys(alcarProducts);
        for (var i = 0, len = keys.length; i < len; ++i) {
            var item = alcarProducts[keys[i]];
            if (item.TyreLine) {
                for (var i2 = 0, len2 = item.TyreLine.length; i2 < len2; ++i2) {
                    size = '';
                    var _size = typeof item.TyreLine[i2].Tyre === 'string' ? item.TyreLine[i2].Tyre.split(' ', 1) : [''];
                    if (_size[0]) {
                        var __size = _size[0].split('R', 2);
                        if (__size[0]) {
                            size = size + __size[0];
                        }
                        if (__size[1]) {
                            size = size + ' R' + __size[1];
                        }
                    }
                    if ('' != size) {
                        if (undefined === sizes[size]) {
                            sizes[size] = 1;
                        } else {
                            sizes[size]++;
                        }
                    }
                }
            }
        }
        if (mostUsed) {
            size = '';
            var used = 0;
            keys = Object.keys(sizes);
            for (var i = 0, len = keys.length; i < len; ++i) {
                if (sizes[keys[i]] > used) {
                    size = keys[i];
                    used = sizes[keys[i]];
                }
            }
            return size;
        }
        return Object.keys(sizes);
    };

    var self = new Alcar();
    App.Alcar = self;

})(jQuery, App);

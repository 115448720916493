(function (App, $) {
    'use strict';

    // need to set pointer point to below
    $.fn.form.settings.templates.prompt = function (errors) {
        return $('<div/>')
            .addClass('ui basic red pointing below prompt label')
            .html(errors[0])
            ;
    };

    var CarForm = function () {
        this.validator = App.Validator;
        this.errorSkeleton = '<div class="error-label ui basic red pointing below prompt label transition visible"></div>';
        this.brands = [];
        this.types = [];
        this.models = [];
        this.ivrc = [];
    };

    CarForm.prototype.init = function (selector) {
        selector = selector || '#car-data-form';

        this.form = $(selector);

        this.initFields();
        this.initDefaultValues();
        this.loadInternationalVehicleRegistrationCodes();

        var self = this;

        this.form.find('.alcar-brand-field.autocomplete-field .ui.dropdown, .alcar-brand-field.autocomplete-field .ui.dropdown .search').one('focus click', function() {
            if (self._loadBrandsCalled) {
                return;
            }
            self.loadBrands();
        });

        this.form.on('submit', function(event) {
            self.submit(event);
        });

        this.form.find('.submit-button').on('click', function (event) {
            self.form.submit();
        });

        this.form.find('.dimmer').on('click', function (event) {
            $(event.currentTarget).dimmer('hide');
        });

        for (var name in this.fields) {
            if (this.fields.hasOwnProperty(name)) {
                var element = this.fields[name].element;
                element.on('change', function (event) {
                    self.isValidField(event.currentTarget.id, true);
                });
                element.on('focusout', function (event) {
                    self.isValidField(event.currentTarget.id, true);
                });
                element.on('keyup', function (event) {
                    if (9 != event.which) {
                        self.isValidField(event.currentTarget.id, true);
                    }
                });
            }
        }
    };

    CarForm.prototype.initFields = function() {
        var self = this;
        this.fields = {
            license_plate_number: {
                element: self.form.find('#license_plate_number'),
                isValid: function(validator, element) {
                    return validator
                        .validate(element.val())
                        .required()
                        .minLen(4)
                        .maxLen(16)
                        .isValid()
                    ;
                }
            },
            international_vehicle_registration_code: {
                element: self.form.find('#international_vehicle_registration_code'),
                isValid: function(validator, element) {
                    return validator
                        .validate(element.val())
                        .ifNotEmpty()
                        .maxLen(4)
                        .isValid()
                    ;
                }
            },
            brand: {
                element: self.form.find('#brand'),
                isValid: function(validator, element) {
                    return validator
                        .validate(element.val())
                        .required()
                        .isValid()
                    ;
                }
            },
            brand_id: {
                element: self.form.find('#brand_id'),
                isValid: function(validator, element) {
                    return validator
                        .validate(element.val())
                        .required()
                        .isValid()
                    ;
                }
            },
            type: {
                element: self.form.find('#type'),
                isValid: function(validator, element) {
                    return validator
                        .validate(element.val())
                        .required()
                        .isValid()
                    ;
                }
            },
            type_id: {
                element: self.form.find('#type_id'),
                isValid: function(validator, element) {
                    return validator
                        .validate(element.val())
                        .required()
                        .isValid()
                    ;
                }
            },
            model: {
                element: self.form.find('#model'),
                isValid: function(validator, element) {
                    return validator
                        .validate(element.val())
                        .required()
                        .isValid()
                    ;
                }
            },
            model_id: {
                element: self.form.find('#model_id'),
                isValid: function(validator, element) {
                    return validator
                        .validate(element.val())
                        .required()
                        .isValid()
                    ;
                }
            },
            name: {
                element: self.form.find('#name'),
                isValid: function() {
                    return true;
                }
            },
            tyre_size: {
                element: self.form.find('#tyre_size'),
                isValid: function() {
                    return true;
                }
            },
            summer_tyre_front_size: {
                element: self.form.find('#summer_tyre_front_size'),
                isValid: function() {
                    return true;
                }
            },
            summer_tyre_rear_size: {
                element: self.form.find('#summer_tyre_rear_size'),
                isValid: function() {
                    return true;
                }
            },
            winter_tyre_front_size: {
                element: self.form.find('#winter_tyre_front_size'),
                isValid: function() {
                    return true;
                }
            },
            winter_tyre_rear_size: {
                element: self.form.find('#winter_tyre_rear_size'),
                isValid: function() {
                    return true;
                }
            },
            mileage: {
                element: self.form.find('#mileage'),
                isValid: function() {
                    return true;
                }
            },
            image: {
                element: self.form.find('#image'),
                isValid: function() {
                    return true;
                }
            }
        };
    };

    CarForm.prototype.isValidField = function (name, displayError) {
        name = name || undefined;
        displayError = displayError || false;
        var valid = true;
        if (this.fields.hasOwnProperty(name)) {
            if (this.fields[name].hasOwnProperty('isValid')) {
                if (!this.fields[name].isValid(this.validator, this.fields[name].element)) {
                    valid = false;
                }
            }
        } else {
            throw 'Form field not exists!';
        }

        if (displayError) {
            var errorLabel = this.fields[name].element.parents('.field').find('.error-label');
            if (errorLabel.length) {
                errorLabel.remove();
            }
            if (!valid) {
                var msg = this.validator.getError();
                var error = this.errorSkeleton;
                this.fields[name].element.parents('.field').append($(error).html(msg));
            }
        }

        return valid;
    };

    CarForm.prototype.isValid = function (displayError) {
        displayError = displayError || false;

        var valid = true;
        for (var name in this.fields) {
            if (this.fields.hasOwnProperty(name) && !this.isValidField(name, displayError)) {
                valid = false;
            }
        }

        return valid;
    };

    CarForm.prototype.reset = function () {
        this.form.trigger('reset');
    };

    CarForm.prototype.submit = function (event) {
        var self = this;

        if (!self.isValid(true)) {
            event.preventDefault();
            return false;
        }

        this.form.addClass('loading');

        return true;
    };

    CarForm.prototype.initDefaultValues = function() {
        var self = this;

        var defaultBrands = [];
        if (self.form.find('#brand_id').val()) {
            defaultBrands.push({
                value: self.form.find('#brand_id').val(),
                name: self.form.find('#brand').val(),
                selected: true
            });
        }
        self.form.find('.alcar-brand-field.autocomplete-field .ui.dropdown').dropdown({
            values: defaultBrands,
            placeholder: '(' + App.t('required') + ')'
        });

        var defaultTypes = [];
        if (self.form.find('#type_id').val()) {
            defaultTypes.push({
                value: self.form.find('#type_id').val(),
                name: self.form.find('#type').val(),
                selected: true
            });
        }
        self.form.find('.alcar-type-field.autocomplete-field .ui.dropdown').dropdown({
            values: defaultTypes,
            placeholder: '(' + App.t('required') + ')'
        });

        var defaultModels = [];
        if (self.form.find('#model_id').val()) {
            defaultModels.push({
                value: self.form.find('#model_id').val(),
                name: self.form.find('#model').val(),
                selected: true
            });
        }
        self.form.find('.alcar-model-field.autocomplete-field .ui.dropdown').dropdown({
            values: defaultModels,
            placeholder: '(' + App.t('required') + ')'
        });
    };

    CarForm.prototype.loadBrands = function () {
        var self = this,
            dropdown = self.form.find('.alcar-brand-field.autocomplete-field .ui.dropdown'),
            selected = dropdown.find('#brand_id').val();

        this._loadBrandsCalled = true;

        dropdown.addClass('loading');

        self.brands = [];

        App.Alcar.getBrands(function (status, brands) {
            dropdown.removeClass('loading');
            if (status) {
                $.each(brands, function (i, item) {
                    self.brands.push({
                        value: item.BrandCode,
                        name: item.BrandText,
                        selected: item.BrandCode == selected
                    });
                });
            }
            dropdown.dropdown({
                values: self.brands,
                fullTextSearch: true,
                placeholder: '(' + App.t('required') + ')',
                onChange: function(value, text, choice) {
                    if (!value.length) {
                        // don't care about empty field
                        return;
                    }

                    self.form.find('#brand').val(text);

                    if (value && value != selected) {
                        self.form.find('.alcar-type-field.autocomplete-field .ui.dropdown').dropdown('clear').dropdown('setup menu', []);
                        self.form.find('.alcar-model-field.autocomplete-field .ui.dropdown').dropdown('clear').dropdown('setup menu', []);
                        self.loadTypes();
                        self.form.find('.alcar-car-image .status-image').empty();
                        self.form.find('.alcar-car-image').removeClass('ready');
                    }
                }
            });
        });
    };

    CarForm.prototype.loadTypes = function () {
        var self = this,
            brandCode = this.form.find('#brand_id').val(),
            dropdown = self.form.find('.alcar-type-field.autocomplete-field .ui.dropdown'),
            selected = dropdown.find('#type_id').val();
        self.types = [];
        if (!brandCode) {
            // if not brand code, unable to load anything
            return;
        }
        dropdown.addClass('loading');
        App.Alcar.getTypes(brandCode, function (status, types) {
            if (status) {
                $.each(types, function (i, item) {
                    self.types.push({
                        value: item.TypeCode,
                        name: item.TypeText,
                        selected: item.TypeCode == selected
                    });
                });
            }
            dropdown.removeClass('loading');
            dropdown.dropdown({
                values: self.types,
                fullTextSearch: true,
                placeholder: '(' + App.t('required') + ')',
                onChange: function(value, text, choice) {
                    self.form.find('#type').val(text);
                    self.form.find('.alcar-model-field.autocomplete-field .ui.dropdown').dropdown('clear').dropdown('setup menu', []);
                    self.loadModels();
                    self.form.find('.alcar-car-image .status-image').empty();
                    self.form.find('.alcar-car-image').removeClass('ready');
                }
            });
        });
    };


    CarForm.prototype.loadModels = function () {
        var self = this,
            brandCode = this.form.find('#brand_id').val(),
            typeCode = this.form.find('#type_id').val(),
            dropdown = self.form.find('.alcar-model-field.autocomplete-field .ui.dropdown'),
            selected = dropdown.find('#model_id').val();

        if (!brandCode || !typeCode) {
            // if missing brand or type code, unable to load anything
            return;
        }
        dropdown.addClass('loading');
        self.models = [];
        App.Alcar.getModels(brandCode, typeCode, function (status, models) {
            if (status) {
                $.each(models, function (i, item) {
                    self.models.push({
                        value: item.ModelCode,
                        name: item.ModelName,
                        selected: item.ModelCode == selected
                    });
                });
            }
            dropdown.removeClass('loading');
            dropdown.dropdown({
                values: self.models,
                fullTextSearch: true,
                // forceSelection: false,
                placeholder: '(' + App.t('required') + ')',
                onChange: function(value, text, choice) {
                    self.form.find('#model').val(text);

                    self.form.find('.alcar-car-image .status-image').empty();
                    self.form.find('.alcar-car-image').removeClass('ready');
                    self.loadImage();
                }
            });
        });
    };

    CarForm.prototype.loadImage = function (forceImage) {
        var self = this,
            modelCode = this.form.find('#model_id').val();
        this.form.find('.alcar-car-image .status-image').empty();
        this.form.find('.alcar-car-image').removeClass('ready');
        if (!modelCode) {
            // if missing the model code, unable to load the image
            return;
        }
        this.form.find('.alcar-car-image').addClass('waiting');

        var showImageAction = function(image) {
            self.form.find('.alcar-car-image').addClass('ready');

            var img = new Image();
            img.onload = function () {
                var modal = self.form.parents('.modal');
                if (modal.length) {
                    modal.modal('refresh');
                }
            };
            img.alt = 'car-image';
            img.src = image;
            img.className = 'ui centered image'; // don't use fluid, because the alcar image is low res

            self.form.find('.alcar-car-image .status-image').append(img);
            self.form.find('#image').val(image);
        };

        if (forceImage) {
            showImageAction(forceImage);
            self.form.find('.alcar-car-image').removeClass('waiting');
            return;
        }

        App.Alcar.getAlcarProducts(modelCode, function (status, alcarProducts) {
            if (status) {
                var size = App.Alcar.getTyreSizes(alcarProducts, true);
                if (size) {
                    self.form.find('#tyre_size').val(size);
                    self.form.find('#tyre_size').trigger('change');
                }
                var model = App.Alcar.getMostBeautifulRimModel(alcarProducts);
                if (model.ImageOnCarBinary) {
                    showImageAction(model.ImageOnCarBinary);
                }
            }
            self.form.find('.alcar-car-image').removeClass('waiting');
        });
    };

    CarForm.prototype.loadInternationalVehicleRegistrationCodes = function () {
        var self = this,
            ivrcBlock = this.form.find('#international_vehicle_registration_code');

        if (!ivrcBlock.length || typeof ivrcBlock.data('load-url') === 'undefined' || !ivrcBlock.data('load-url').length) {
            return;
        }

        this.form.find('.ivrc-field .input').addClass('loading');

        $.ajax({
            url: ivrcBlock.data('load-url')
        }).done(function(data) {
            self.form.find('.ivrc-field .input').removeClass('loading');
            var wait = false;

            $.each(data, function (i, item) {
                self.ivrc.push({
                    value: item,
                    title: item
                });
            });

            self.form.find('.ivrc-field.autocomplete-field').search({
                source: self.ivrc,
                searchFields: [
                    'title'
                ],
                minCharacters: 0,
                maxResults: 0,
                fullTextSearch: true,
                onSelect: function (result, response) {
                    wait = true;
                    // block reopening results, for a moment
                    setTimeout(function () {
                        wait = false;
                    }, 100);
                    var oldValue = self.form.find('#international_vehicle_registration_code').val();
                    self.form.find('#international_vehicle_registration_code').val(result.title);
                    self.form.find('.ivrc-field .results').transition();
                    // need to return false, because we need to take control over semantic
                    return false;
                },
                onResultsOpen: function () {
                    if (wait) {
                        // do not open result while wait
                        return false;
                    }
                }
            });
        });
    };

    App.CarForm = new CarForm();
    App.CarForm.__class = CarForm;
})(App, jQuery);

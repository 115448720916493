
(function ($,App) {

    // need to set pointer point to below
    $.fn.form.settings.templates.prompt = function (errors) {
        return $('<div/>')
            .addClass('ui basic red pointing below prompt label')
            .html(errors[0])
            ;
    };

    var ContactForm = function () {
        var self = this;
        this.form = $('#contact-form');
        this.errorSkeleton = '<div class="error-label ui basic red pointing below prompt label transition visible"></div>';
        this.fields = {
            contact_name: {
                element: $('#contact_name'),
                isValid: function (validator, element) {
                    return validator.validate(element.val())
                        .required()
                        .isValid();
                }
            },
            contact_email: {
                element: $('#contact_email'),
                isValid: function (validator, element) {
                    if ( 0 < self.fields.contact_phone.element.val().length ) {
                        return validator.validate(element.val())
                            .ifNotEmpty().email()
                            .isValid();
                    } else {
                        return validator.validate(element.val())
                            .required().email()
                            .isValid();
                    }
                }
            },
            contact_phone: {
                element: $('#contact_phone'),
                isValid: function (validator, element) {
                    var mask = element.data('mask');
                    var format = "" + element.data('mask-human');
                    if (!format.length) {
                        format = mask;
                    }
                    if ( 0 < self.fields.contact_email.element.val().length ) {
                        return validator.validate(element.val())
                            .ifNotEmpty().mask(mask, App.t('The format must be: %mask%', { '%mask%': format }))
                            .isValid();
                    } else {
                        return validator.validate(element.val())
                            .required().mask(mask, App.t('The format must be: %mask%', { '%mask%': format }))
                            .isValid();
                    }
                }
            },
            contact_message: {
                element: $('#contact_message'),
                isValid: function (validator, element) {
                    return validator.validate(element.val())
                        .required()
                        .isValid();
                }
            },
            contact_privacy_accepted: {
                element: $('#contact_privacy_accepted'),
                isValid: function (validator, element) {
                    var v ='';
                    if (element.prop('checked')) {
                        v = element.val();
                    }
                    return validator.validate(v)
                        .required()
                        .isValid();
                }
            }
        };

    };

    ContactForm.prototype.init = function (validator) {

        var self = this;
        this.validator = validator;

        this.form.on('submit', function(event) {
            event.preventDefault();
            self.submit();
        });

        this.form.find('.submit-button').on('click', function(event){
            self.form.submit();
        });

        this.form.find('.dimmer').on('click', function(event) {
            $(event.currentTarget).dimmer('hide');
        });

        for (var name in this.fields) {
            var element = this.fields[name].element;
            element.on('change', function(event){
                self.isValidField(event.currentTarget.id, true);
            });
            element.on('focusout', function(event){
                self.isValidField(event.currentTarget.id, true);
            });
            element.on('keyup', function(event){
                if (9 != event.which) {
                    self.isValidField(event.currentTarget.id, true);
                }
            });
        }

    };

    ContactForm.prototype.isValidField = function(name, displayError) {
        name = name || undefined;
        displayError = displayError || false;
        var valid = true;
        if (this.fields.hasOwnProperty(name)) {
            if (this.fields[name].hasOwnProperty('isValid')) {
                if (!this.fields[name].isValid(this.validator, this.fields[name].element)) {
                    valid = false;
                }
            }
        } else {
            throw "Form field not exists!";
        }

        if (displayError) {
            var errorLabel = this.fields[name].element.parents('.field').find('.error-label');
            if (errorLabel.length) {
                errorLabel.remove();
            }
            if (!valid) {
                var msg = this.validator.getError();
                var error = this.errorSkeleton;
                this.fields[name].element.parents('.field').append($(error).html(msg));
            }
        }

        return valid;
    };

    ContactForm.prototype.isValid = function(displayError) {
        displayError = displayError || false;

        var valid = true;
        for (var name in this.fields) {
            if (!this.isValidField(name, displayError)) {
                valid = false;
            }
        }

        return valid;
    };

    ContactForm.prototype.getData = function() {
        var data = {};
        for (var key in this.fields) {
            if (this.fields[key].hasOwnProperty('value')) {
                data[key] = this.fields[key].value();
            } else {
                data[key] = $('#' + key).val();
            }
        }
        return data;
    };

    ContactForm.prototype.reset = function() {
        this.form.trigger('reset');
    };

    ContactForm.prototype.submit = function() {
        var self = this;

        if (!self.isValid(true)) {
            return;
        }

        this.form.addClass('loading');

        $.ajax({
            type: "POST",
            url: self.form.attr('action'),
            dataType: "json",
            data: self.getData(),
            success: function(data) {
                self.form.removeClass('loading');
                switch (data.status) {
                    case 'success':
                        self.form.find('.dimmer.success').dimmer('show');
                        var element = self.form.find('.dimmer.success .messages');
                        element.empty();
                        if (data.success.messages) {
                            for ( i = 0, len = data.success.messages.length; i < len; ++i) {
                                element.append('<p>' + data.success.messages[i] + '</p>');
                            }
                        }
                        self.reset();
                        break;
                    case 'error':
                        self.form.find('.dimmer.error').dimmer('show');
                        var element = self.form.find('.dimmer.error .messages');
                        element.empty();
                        if (data.error.messages) {
                            for ( i = 0, len = data.error.messages.length; i < len; ++i) {
                                element.append('<p>' + data.error.messages[i] + '</p>');
                            }
                        }
                        break;
                }
            },
            error: function(data) {
                self.form.removeClass('loading');
            }
        });
    };

    App.ContactForm = ContactForm;

    // if on contact page
    if ($('#contact-form').length) {
        var contactForm = new ContactForm();
        contactForm.init(App.Validator);
    }

})(jQuery, App);


(function($,App){
    var modalButton = $('#contact-modal-switch');

    if (!modalButton.length) {
        return;
    }

    $('#contact-modal').modal({
        onShow: function(e) {
            var modal = $(this);
            var loadUrl = modal.data('load-url');
            modal.load(loadUrl, function() {
                var msg = modalButton.data('message');
                if (msg) {
                    msg = msg.split(';');
                    if (msg.length) {
                        var textarea = '';
                        for (var i in msg) {
                            textarea += msg[i] + "\n";
                        }
                        $('#contact_message').val(textarea);
                    }
                }
            });
        }
    });

    modalButton.on('click', function(){
        $('#contact-modal').modal('show');
    });

    $('#contact-modal').on('click', '.close-button', function(event) {
        $('#contact-modal').modal('hide');
    });

})(jQuery, App);

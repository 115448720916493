
(function (App, $) {

    $('.quicklinks-menu .scroll-to').on('click', function(event){
        event.preventDefault();
        var id = $(event.currentTarget).data('target');
        var toElement = $(id);
        App.Utils.scrollTo(toElement, -120);
    });

})(App, jQuery);

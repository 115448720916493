
(function($) {
    // header panel seach box things

    function SearchForm() {
        this.query = [];
        this.tags = $('#free-hand-search-tags');
        this.searchInput = $('#free-hand-search');
        this.searchInputDebounceTimeout = null;
        this.searchResults = $('#search-info .results');
        this.searchPopularSearches = $('#search-info .popular-searches');
        this.searchPopularBrands = $('#search-info .popular-brands');
        this.searchPopularTags = $('#search-info .popular-tags');
        this.backdrop = $('#search-box-backdrop');
        this.searchRequest = null;
        this.keyboardEventsActive = false;
        this.usageMessage = this.searchInput.data('right-usage');
    }

    SearchForm.prototype.init = function () {
        if (0 == this.searchInput.length) {
            return;
        }
        var tags = this.searchInput.data('selected-tags');
        for (var i = 0, len = tags.length; i < len; i++) {
            this.addTag(tags[i].slug, tags[i].name);
        }

        $('#search-category-dropdown').dropdown({
            onChange: function(value, text, $selectedItem) {
                self.categoryChanged(value, text, $selectedItem);
            }
        });
        $('#search-car-dropdown').dropdown({
            onChange: function(value, text, $selectedItem) {
                var size = '';
                if ($selectedItem) {
                    size = $selectedItem.data('size');
                }
                value = value || '';
                self.carChanged(value, size);
            }
        });

        $('#free-hand-search-button').on('click', function(event){
            self.search();
        });

        this.searchInput.on('input',function(event){
            var query = $(event.currentTarget).val();
            if (query && (1<query.length)) {
                clearTimeout(self.searchInputDebounceTimeout);
                self.searchInputDebounceTimeout = setTimeout(function() { self.searchTag(query); }, 400);
            }
        });

        $('#free-hand-search-tags').on('click', '.tag', function(event){
            $(event.currentTarget).remove();
        });

        this.searchInput.on('focusin', function(event){
            if (!self.keyboardEventsActive) {
                self.keyboardEventsActive = true;
                self.backdrop.addClass('open');
                self.searchInput.addClass('focus');
                self.addKeyboardEvents();
            }
        }).on('focusout', function(event) {
            if (self.keyboardEventsActive) {
                self.keyboardEventsActive = false;
                setTimeout(function () {
                        self.removeKeyboardEvents();
                    },
                    300
                );
            }
        });

        self.backdrop.on('click', function(event){
            self.backdrop.removeClass('open');
            self.searchInput.removeClass('focus');
        });

        $('#search-info .results').on('mouseenter', '.item', function(event){
            $(event.currentTarget).addClass('selected');
        }).on('mouseleave', '.item', function(event) {
            $(event.currentTarget).removeClass('selected');
        });

        $('#search-info .results').on('click', '.item', function(event) {
            var item = $(event.currentTarget);
            var value = item.data('value');
            var label = item.data('label');
            self.addSearch(value, label);
            setTimeout(function() {
                self.searchInput.focus();
            }, 400);
        });

        $('#search-info .mass-tags').on('click', '.add-tag-button', function(event) {
            event.stopPropagation();
            var item = $(event.currentTarget);
            var value = item.data('value');
            var label = item.data('label');
            self.addSearch(value, label);
            setTimeout(function() {
                self.searchInput.focus();
            }, 400);
        });

    };

    SearchForm.prototype.categoryChanged = function(value, label, element) {
        this.emptyTags();
        this.query = [];
        this.query.push(value);
        if ('szemelygepkocsi-abroncs' == value) {
            $('#search-car-dropdown').removeClass('invisible');
        } else {
            $('#search-car-dropdown').addClass('invisible');
            self.carsReset();
        }
        var placeholder = element.data('placeholder');
        this.searchInput.prop('placeholder', placeholder);
        this.addPopularSearches(element.data('popular_searches'));
        this.addPopularBrands(element.data('popular_brands'));
        this.addPopularTags(element.data('popular_tags'));
    };

    SearchForm.prototype.addPopularTags = function(data) {
        this.searchPopularTags.find('.add-tag-button').each(function() {
            $(this).remove();
        });
        if (!data) {
            this.searchPopularTags.addClass('invisible');
            return;
        }

        var tags = this.searchPopularTags.find('.tags');
        var items = data.split(';');
        for (var key in items) {
            var title_url = items[key].split('@');
            var item = '<button data-label="' + title_url[0] + '" data-value="' + title_url[1] + '" type="button" class="add-tag-button ui small gray clear button"><i class="add square icon"></i>' + title_url[0] + '</button>';
            tags.append(item);
        }

        this.searchPopularTags.removeClass('invisible');
    };

    SearchForm.prototype.addPopularBrands = function(data) {
        this.searchPopularBrands.find('.add-tag-button').each(function() {
            $(this).remove();
        });
        if (!data) {
            this.searchPopularBrands.addClass('invisible');
            return;
        }

        var tags = this.searchPopularBrands.find('.tags');
        var items = data.split(';');
        for (var key in items) {
            var title_url = items[key].split('@');
            var item = '<button data-label="' + title_url[0] + '" data-value="' + title_url[1] + '" type="button" class="add-tag-button ui small gray clear button"><i class="add square icon"></i>' + title_url[0] + '</button>';
            tags.append(item);
        }

        this.searchPopularBrands.removeClass('invisible');
    };

    SearchForm.prototype.addPopularSearches = function(data) {
        this.searchPopularSearches.find('.item').each(function() {
            var item = $(this);
            if (!item.hasClass('header')) {
                item.remove();
            }
        });
        if (!data) {
            this.searchPopularSearches.addClass('invisible');
            return;
        }

        var items = data.split(';');
        for (var key in items) {
            var title_url = items[key].split('@');
            var item = '<a href="' + title_url[1] + '" class="item"><span class="middle aligned content"><i class="search icon"></i>' + title_url[0] + '</span></a>';
            this.searchPopularSearches.append(item);
        }

        this.searchPopularSearches.removeClass('invisible');
    };

    SearchForm.prototype.carChanged = function(value, label) {
        if ('' == value) {
            this.emptyTags();
            this.query = [];
        } else {
            this.addTag(value, label);
        }
    };

    SearchForm.prototype.carsReset = function() {
        $('#search-car-dropdown').dropdown('restore defaults');
    };

    SearchForm.prototype.addTag = function(value, label) {
        var tag = '<span class="ui mini tag label" data-value="'+value+'">' + label + '</span>';
        this.tags.append(tag);
    };

    SearchForm.prototype.emptyTags = function() {
        this.tags.empty();
    };

    SearchForm.prototype.addSearch = function(value, label) {
        this.addTag(value, label);
        this.searchInput.val('');
        this.searchResults.empty();
    };

    SearchForm.prototype.searchTag = function(search) {
        if (this.searchRequest) {
            this.searchRequest.abort();
        }
        var tags = [];
        $('#free-hand-search-tags > .tag').each(function(i,item) {
            tags.push($(item).data('value'));
        });
        var data = {
            'search': search,
            'category': $('#search_category').val(),
            'tags': tags.join('/')
        };
        $('#free-hand-search-button').addClass('waiting');
        this.searchResults.empty();
        this.searchRequest = $.ajax({
            type: "POST",
            dataType: 'json',
            url: '/ajax/product-search/autocomplete',
            data: data
        }).done(function (data) {
            if (data.success) {
                var results = data.results;
                if (results.length) {
                    var element = '';
                    for (var i = 0, len = results.length; i < len; ++i) {
                        element = '<div class="item" data-value="'+results[i].value+'" data-label="'+results[i].name+'"><div class="middle aligned content"><i class="marso-icon-search icon"></i>'+results[i].name+'</div></div>';
                        self.searchResults.append(element);
                    }
                    self.nextResult();
                }
            }
        }).fail(function (data) {
        }).always(function(data) {
                $('#free-hand-search-button').removeClass('waiting');
            }
        );
    };

    SearchForm.prototype.search = function() {
        var url = $('#search-box').data('url');
        url += '/' + $('#search_category').val();
        var tags = [];
        $('#free-hand-search-tags > .tag').each(function(i,item) {
            tags.push($(item).data('value'));
        });
        url += '/' + tags.join('/');
        window.location.href = url;
    };

    SearchForm.prototype.bindKeys = function(event) {
        switch (event.which) {
            case 13: // enter
                var value = self.searchInput.val();
                if ('' == value) {
                    self.search();
                } else {
                    if ($('#search-info .results .item').length) {
                        self.chooseResult();
                    } else {
                        App.Messages.add(self.usageMessage, 'info', 2);
                    }
                }
                break;
            case 38: // up
                self.previousResult();
                break;
            case 40: // down
                self.nextResult();
                break;
            default: return;
        }
        event.preventDefault();
    };

    SearchForm.prototype.chooseResult = function() {
        $('#search-info .results .item.selected').click();
    };

    SearchForm.prototype.nextResult = function() {
        var items = $('#search-info .results .item');
        for (var i = 0, len = items.length; i < len; i++) {
            if ($(items[i]).hasClass('selected')) {
                $(items[i]).removeClass('selected');
                $(items[(i+1)]).addClass('selected');
                return;
            }
        }
        $(items[0]).addClass('selected');
    };

    SearchForm.prototype.previousResult = function() {
        var items = $('#search-info .results .item');
        for (var i = 0, len = items.length; i < len; i++) {
            if ($(items[i]).hasClass('selected')) {
                $(items[i]).removeClass('selected');
                $(items[(i-1)]).addClass('selected');
                return;
            }
        }
        $(items[(items.length - 1)]).addClass('selected');
    };

    SearchForm.prototype.addKeyboardEvents = function() {
        $(document).on('keydown', self.bindKeys);
    };

    SearchForm.prototype.removeKeyboardEvents = function() {
        $(document).off('keydown', self.bindKeys);
    };

    // init
    var self = new SearchForm();
    self.init();



})(jQuery);

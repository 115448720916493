
(function ($,App) {

    $.fn.form.settings.templates.prompt = function(errors) {
        return $('<div/>')
            .addClass('ui basic red pointing below prompt label')
            .html(errors[0])
            ;
    };

    // offer request form validate js
    $('#offer-request-form').form({
        fields: {
            offer_request_name: {
                identifier: 'offer_request_name',
                rules: [
                    {
                        type: 'empty',
                        prompt: App.t('Please fill this field!')
                    }
                ]
            },
            offer_request_phone: {
                identifier: 'offer_request_phone',
                rules: [
                    {
                        type: 'empty',
                        prompt: App.t('Please fill this field!')
                    }
                ]
            },
            offer_request_email: {
                identifier: 'offer_request_email',
                rules: [
                    {
                        type: 'empty',
                        prompt: App.t('Please fill this field!')
                    },
                    {
                        type   : 'email',
                        prompt : App.t('Please enter a valid e-mail!')
                    }
                ]
            }
        },
        inline : true,
        on     : 'blur'
    });

    $('#send-offer-request-button').on('click', function(event) {
        $('#offer-request-form').submit();
    });

})(jQuery, App);


(function (App, $) {

    $('.services-box .service-request-form').on('submit',function(event){
        event.preventDefault();
        var form = $(event.currentTarget);
        var productSku = form.find('[name=product_sku]').val();
        App.Cart.addRequest(productSku, 0,
            function(status, data) {
                if (!status) {
                    App.Messages.add(data.message, 'info', 10);
                } else {
                    var link = window.location.href;
                    window.location.href = link;
                }
            }
        );
    });

})(App, jQuery);

(function($){

    var place = $('.in-news-banner');

    if (0 === place.length) {
        return;
    }

    $.ajax({
        type: "POST",
        dataType: 'json',
        url: '/ajax/banners/get-banner',
        data: {place: 'in_news'},
        success: function(data) {
            if ('success' === data.status) {
                if (Array.isArray(data.results) && (data.results.length)) {
                    var i = data.results.length;
                    while (i > 0) {
                        i--;
                        var banner = data.results[i];
                        var html = $('<div class="in-news-banner"><a class="ui image" href="' + banner.url + '" target="_blank"><img src="' + banner.image + '" alt=""/></a></div>');
                        place.find('p').each(function(i, p){
                            if (1 === i) {
                                html.insertAfter($(p));
                            }
                        });
                    }
                }
            }
        },
        error: function(data) {
            // do nothing
        }
    });

})(jQuery);

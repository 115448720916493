(function (App, $) {
    'use strict';

    // $('storage-status')

    var StockUpdater = function () {
        this.apiUrl = '/refresh-stock-info-action';
        this.cartApiUrl = '/ajax/cart-api/refresh-product-block';
        this.loadingSpinner = '<div class="ui active inverted dimmer"><div class="ui loader"></div></div>';
    };

    StockUpdater.prototype.refreshStockInfos = function () {
        var self = this,
            fields = $('.storage-status'),
            products = [];

        for (var i = 0; i < fields.length; i++) {
            var sku = $(fields[i]).data('manufacturers-stock-update');
            if (sku) {
                products.push({ sku: sku });
            }
        }

        if (!products.length) {
            return;
        }

        for (var i = 0; i < products.length; i++) {
            var el = $('[data-manufacturers-stock-update="' + products[i].sku + '"]');

            el.append(this.loadingSpinner);
        }

        $.ajax({
            type: 'POST',
            url: self.apiUrl,
            data: { products: products }
        }).done(function (data) {
            for (var i = 0; i < data.length; i++) {
                var stockInfo = data[i],
                    el = $('[data-manufacturers-stock-update="' + stockInfo.product.sku + '"]'),
                    template = $($('template#stock-info-' + stockInfo.class).html());

                template.data('manufacturers-stock-update', stockInfo.product.sku);
                // data not refreshes attributes
                template.attr('data-manufacturers-stock-update', stockInfo.product.sku);

                if (el.hasClass('huge')) {
                    template.addClass('huge');
                }

                el.replaceWith(template);

                // reinit popup
                template.popup({
                    inline: true,
                    hoverable: true,
                    position: 'bottom left'
                });
            }
        }).always(function () {
            for (var i = 0; i < products.length; i++) {
                var el = $('[data-manufacturers-stock-update="' + products[i].sku + '"]');

                el.find('.dimmer').remove();
            }
        });
    };

    StockUpdater.prototype.cartUpdater = function () {
        var self = this,
            fields = $('.storage-status'),
            products = [];

        for (var i = 0; i < fields.length; i++) {
            var id = $(fields[i]).data('manufacturers-stock-update');
            if (id) {
                products.push({ id: id });
            }
        }

        if (!products.length) {
            return;
        }

        for (var i = 0; i < products.length; i++) {
            var el = $('[data-manufacturers-stock-update="' + products[i].id + '"]').parents('.attached.segment');

            el.append(this.loadingSpinner);
        }

        $.ajax({
            type: 'POST',
            url: self.cartApiUrl,
            data: { products: products }
        }).done(function (data) {
            for (var i = 0; i < data.length; i++) {
                var stockInfo = data[i],
                    el = $('[data-manufacturers-stock-update="' + stockInfo.productid + '"]').parents('.attached.segment'),
                    template = $(stockInfo.block);

                el.replaceWith(template);

                // reinit popup
                template.find('.storage-status').popup({
                    inline: true,
                    hoverable: true,
                    position: 'bottom left'
                });

                // quantity stepper functions
                App.addQuantityStepperEvents(template);
                App.Cart.addEvents(template);
            }
        }).always(function () {
            for (var i = 0; i < products.length; i++) {
                var el = $('[data-manufacturers-stock-update="' + products[i].id + '"]').parents('.attached.segment');

                el.find('.dimmer').remove();
            }
        });
    };

    App.ManufacturersStockUpdater = new StockUpdater();

    if ($('#cart').length) {
        // we are on cart page
        window.setInterval(App.ManufacturersStockUpdater.cartUpdater, 1000 * 60 * 5);
    } else {
        window.setTimeout(function () {
            App.ManufacturersStockUpdater.refreshStockInfos();
            window.setInterval(function() {
                App.ManufacturersStockUpdater.refreshStockInfos();
            }, 1000 * 60 * 5);
        }, 2000);
    }

})(App, jQuery);

var ProductSearchJS = function() {

(function (App, $) {
    $('#search-menu-panel .switch-form').on('click',function(event){
        var button = $(event.currentTarget);
        if (button.hasClass('active')) {
            return false;
        }
        $('#search-menu-panel .switch-form').removeClass('active');
        $('#search-forms .product-search-form').addClass('invisible');
        var id = button.data('form');
        button.addClass('active');
        $(id).removeClass('invisible');
    });
})(App, jQuery);

// (function (App, $) {
//     // size fields
//     // $('.product-search-form .dropdown').dropdown({
//     //     forceSelection: true,
//     //     direction: 'downward'
//     // });
// })(App, jQuery);

(function (App, $) {
    var shiftDown = $('#page-header').outerHeight();
    shiftDown += 4;
    shiftDown *= -1;
    var accordion = $('.product-search-forms-accordion.accordion');
    var accordionTitles = accordion.find('.accordion-title');
    var accordionContents = accordion.find('.accordion-content');

    if (accordion.length) {
        accordionContents.each(function(i, element){
            $(element).data('height', $(element).outerHeight()).css('height', '0px');
        });
        accordionTitles.on('click', function(event) {
            var title = $(this);
            if (!title.hasClass('active')) {
                var prevTitle = accordion.find('.accordion-title.active');
                var content = title.addClass('active').next();
                content.addClass('active').css('height', content.data('height'));
                if (prevTitle.length) {
                    var prevContent = prevTitle.removeClass('active').next();
                    prevContent.css('height', '0px').removeClass('active');
                }
                setTimeout(function() {

                    App.Utils.scrollTo(title, shiftDown, 500);
                }, 600);
            } else {
                var content = title.removeClass('active').next();
                content.css('height', '0px').removeClass('active');
            }
        });
        //accordion.find('.accordion-title').first().click();
    }
})(App, jQuery);

(function (App, $) {
    function refreshCheckbox(form, key, values) {
        if (!Array.isArray(values)) {
            values = [ values ];
        }
        form.find('.checkbox-field .'+key).each(function(index, elem){
            elem = $(elem);
            if (-1 == $.inArray(elem.prop('value'), values)) {
                elem.prop('disabled', true);
                elem.parents('.checkbox-field').addClass('disabled');
            } else {
                elem.prop('disabled', false);
                elem.parents('.checkbox-field').removeClass('disabled');
            }
        });
    }

    function refreshSelect(form, key, values) {
        if (!Array.isArray(values)) {
            values = [ values ];
        }
        form.find('.select-field .'+key+' option').each(function(index, elem){
            elem = $(elem);
            var value = elem.prop('value');
            if (value) {
                if (-1 == $.inArray(value, values)) {
                    elem.addClass('invisible');
                } else {
                    elem.removeClass('invisible');
                }
            }
        });
    }

    function refreshOptions(form, elements, options) {
        $.each(elements, function(_, key) {
            var value = [];
            switch (key) {
                case 'manufacturer_id':
                case 'season':
                case 'car_type':
                case 'run_flat':
                case 'reinforced_version':
                case 'rim_fringe_protector':
                case 'fuel_efficiency':
                case 'wet_grip_class':
                case 'noise_measurement_class':
                case 'machine_type':
                case 'tyre_type':
                    if (options[key]) {
                        value = options[key];
                    }
                    refreshCheckbox(form, key, value);
                    break;
                case 'width':
                case 'profile':
                case 'diameter':
                case 'holes':
                case 'bolt_pattern':
                case 'center_bore':
                case 'wheel_offset':
                    if (options[key]) {
                        value = options[key];
                    }
                    refreshSelect(form, key, value);
                    break;
                default: break;
            }
        });
    }

    $('.product-search-form').on('change', function(event) {
        var changed = $(event.target);
        var form = $(event.currentTarget);
        var refreshUrl = form.data('refresh-url');
        var formData = form.serializeArray();
        var button = form.find('.show-results');
        form.find('.search-status').addClass('visible');
        button.addClass('loading');
        $.ajax({
            url: refreshUrl,
            data: formData,
            dataType: 'json',
            method: 'post'
        }).done(function(data){
            button.removeClass('loading');
            if (data.success) {
                button.find('.found-counter').text(data.results.total);
                refreshOptions(form, data.results.form_elements, data.results.options);
                App.Messages.add(button.text(), 'info', 3);
            }
        });
    });
})(App, jQuery);

};

export default ProductSearchJS;

(function ($, App) {
    var modalButton = $('#newsletter-subscribe-modal-switch');

    if (!modalButton.length) {
        return;
    }

    $('#newsletter-subscribe-modal').modal({
        onShow: function (e) {
            var modal = $(this);
            var loadUrl = modal.data('load-url');
            modal.load(loadUrl);
        }
    });

    modalButton.on('click', function () {
        $('#newsletter-subscribe-modal').modal('show');
    });

    var newsletterCampaign = $('#newsletter-popup-campaign');
    if (newsletterCampaign.length) {
        if (App.Storage.isAvailable()) {
            var reactivate = newsletterCampaign.data('reactivate');
            var now = new Date().getTime() / 1000;
            var c = App.Storage.get('kiskershop.newsletter_popup_campaign');
            if (!c || (c < now)) {
                var popupWait = newsletterCampaign.data('popup-wait') * 1000;
                setTimeout(function () {
                    c = now + (reactivate * 86400);
                    App.Storage.set('kiskershop.newsletter_popup_campaign', c);
                    modalButton.click();
                }, popupWait);
            }
        }
    }

})(jQuery, App);

(function ($, App) {
    // need to set pointer point to below
    $.fn.form.settings.templates.prompt = function (errors) {
        return $('<div/>')
            .addClass('ui basic red pointing below prompt label')
            .html(errors[0])
            ;
    };

    var NewsletterForm = function (form) {
        this.form = form;

        this.fields = {
            newsletter_email: {
                identifier: 'newsletter_email',
                rules: [
                    {
                        type: 'empty',
                        prompt: App.t('Please fill this field!')
                    },
                    {
                        type: 'email',
                        prompt: App.t('Please enter a valid e-mail!')
                    }
                ]
            },
            newsletter_name: {
                identifier: 'newsletter_name',
                rules: [
                    {
                        type: 'empty',
                        prompt: App.t('Please fill this field!')
                    }
                ]
            },
            newsletter_privacy_accepted: {
                identifier: 'newsletter_privacy_accepted',
                rules: [
                    {
                        type: 'checked',
                        prompt: App.t('Please fill this field!')
                    }
                ]
            }
        };
    };

    NewsletterForm.prototype.init = function () {
        if (!this.form.length) {
            return;
        }

        var self = this;

        this.form.form({
            fields: this.fields,
            inline: true,
            on: 'blur'
        });

        this.form.on('submit', function (event) {
            event.preventDefault();
            if (!self.form.form('is valid')) {
                return;
            }
            self.form.find('.dimmer .messages').empty();
            var loading = self.form.find('.dimmer.loading');
            loading.dimmer('show');
            $.ajax({
                type: "POST",
                url: self.form.attr('action'),
                dataType: "json",
                data: self.form.serialize(),
                success: function (data) {
                    loading.dimmer('hide');
                    switch (data.status) {
                        case 'success':
                            self.reset();
                            self.form.find('.dimmer.success').dimmer('show');
                            var element = self.form.find('.dimmer.success .messages');
                            if (data.success.messages) {
                                for (i = 0, len = data.success.messages.length; i < len; ++i) {
                                    element.append('<p>' + data.success.messages[i] + '</p>');
                                }
                            }
                            break;
                        case 'error':
                            self.form.find('.dimmer.error').dimmer('show');
                            var element = self.form.find('.dimmer.error .messages');
                            if (data.error.messages) {
                                for (i = 0, len = data.error.messages.length; i < len; ++i) {
                                    element.append('<p>' + data.error.messages[i] + '</p>');
                                }
                            }
                            break;
                    }
                },
                error: function (data) {
                    loading.dimmer('hide');
                    self.form.find('.dimmer.error').dimmer('show');
                    var element = self.form.find('.dimmer.error .messages');
                    element.append('<p>' + data.statusText + '</p>');

                }
            });
        });

        this.form.find('.submit-button').on('click', function (event) {
            self.form.submit();
        });

        this.form.find('.dimmer').on('click', function (event) {
            $(event.currentTarget).dimmer('hide');
        });

    };

    NewsletterForm.prototype.reset = function () {
        this.form.find('.reset-button').click();
    };

    var newsletterForm = new NewsletterForm($('#newsletter-form'));
    newsletterForm.init();

    App.NewsletterForm = NewsletterForm;
})(jQuery, App);

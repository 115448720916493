(function($) {
    $('.login-form input#email').keydown(function(e) {
        if (e.which == 13 || e.keyCode == 13) {
            e.preventDefault();

            $(this)
                .parents('form')
                .find('button[type="submit"]:visible')
                .trigger('click');

            return false;
        }
        return true;
    });

    $('.login-form .login-forgot').click(function() {
        $(this)
            .parent('.login-group')
            .slideUp()
            .next('.reset-group')
            .slideDown();
        $(this)
            .parents('form')
            .find('.password')
            .slideUp();
    });

    $('.login-form .login-remembered').click(function() {
        $(this)
            .parent('.reset-group')
            .slideUp()
            .prev('.login-group')
            .slideDown();
        $(this)
            .parents('form')
            .find('.password')
            .slideDown();
    });

    var dimmerTemplate =
        '<div class="ui dimmer">' +
        '<div class="content">' +
        '   <div class="center">' +
        '     <i class="notched circle loading huge icon"></i>' +
        '   </div>' +
        '</div>' +
        '</div>';

    var loginEmailCheckHandler = function(e) {
        var form = $(this),
            formContainer = form.parents('.ui.form-block.active');

        $.ajax({
            type: 'POST',
            dataType: 'html',
            url: form.attr('action'),
            data: form.serialize(),
            beforeSend: function() {
                formContainer
                    .dimmer({
                        closable: false,
                        template: {
                            dimmer: function() {
                                return dimmerTemplate;
                            }
                        }
                    })
                    .dimmer('show');
            }
        })
            .done(function(html) {
                form.parent().replaceWith(html);
                formContainer.find('input[autofocus]').focus();
                formContainer.find('#login-check-email').on('submit', loginEmailCheckHandler);
                formContainer.find('#login-existing-user').on('submit', loginExistingUserHandler);
                formContainer.find('#login-forgot-password').click(loginForgotPassswordHandler);
                formContainer.find('#registration-new-user').on('submit', registrationNewUserHandler);
            })
            .always(function() {
                formContainer.dimmer('hide');
            });

        e.preventDefault();
        return false;
    };

    $('#login-check-email').on('submit', loginEmailCheckHandler);

    var loginForgotPassswordHandler = function(e) {
        $('#forgot-password-modal')
            .modal('setting', {
                onApprove: function() {
                    var form = $('#login-forgot-password-form'),
                        formContainer = form.parents('.ui.form-block.active');

                    $.ajax({
                        type: 'POST',
                        dataType: 'json',
                        url: form.attr('action'),
                        data: form.serialize(),
                        beforeSend: function() {
                            form.find('.message').css('display', 'none');
                            formContainer
                                .dimmer({
                                    closable: false,
                                    template: {
                                        dimmer: function() {
                                            return dimmerTemplate;
                                        }
                                    }
                                })
                                .dimmer('show');
                        }
                    }).done(function(json) {
                        var passwordForm = $('#login-existing-user'),
                            data = [],
                            type = 'error';

                        if (typeof json.error != 'undefined') {
                            data = json.error;
                            type = 'error';
                        } else if (typeof json.success != 'undefined') {
                            data = json.success;
                            type = 'success';
                        }

                        var message = passwordForm.find('.' + type + '.message').first(),
                            container = $('<div>');
                        for (var i = 0; i < data.length; i++) {
                            var clone = message.clone();
                            clone.find('.content p').text(data[i]);
                            container.append(clone);
                        }
                        message.parent('div').replaceWith(container);
                        container.find('.' + type + '.message').css('display', 'flex');
                        formContainer.dimmer('hide');
                    });
                }
            })
            .modal('show');
    };

    var loginExistingUserHandler = function(e) {
        var form = $(this),
            formContainer = form.parents('.ui.form-block.active');

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: form.attr('action'),
            data: form.serialize(),
            beforeSend: function() {
                formContainer
                    .dimmer({
                        closable: false,
                        template: {
                            dimmer: function() {
                                return dimmerTemplate;
                            }
                        }
                    })
                    .dimmer('show');
            }
        }).done(function(json) {
            if (typeof json.error != 'undefined') {
                var messageTemplate = $('.message-skeleton');
                var message = [];
                for (var i = 0; i < json.error.length; i++) {
                    message.push(json.error[i]);
                }
                var clone = messageTemplate.clone();
                clone.removeClass('invisible message-skeleton');
                clone.text(message.join('<br>'));
                clone.addClass('visible');
                $('.field.password').append(clone);
                formContainer.dimmer('hide');
            } else if (typeof json.redirect != 'undefined') {
                window.location.replace(json.redirect);
            }
        });

        e.preventDefault();
        return false;
    };

    var registrationNewUserHandler = function(e) {
        var form = $(this),
            formContainer = form.parents('.ui.form-block.active');

        if (form.find('button[type="submit"]').prop('disabled')) {
            // if submit button disabled, do not try to resend form
            e.preventDefault();
            return false;
        }

        form.find('button[type="submit"]').prop('disabled', true);

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: form.attr('action'),
            data: form.serialize(),
            beforeSend: function() {
                formContainer
                    .dimmer({
                        closable: false,
                        template: {
                            dimmer: function() {
                                return dimmerTemplate;
                            }
                        }
                    })
                    .dimmer('show');
            }
        }).done(function(json) {
            form.find('.prompt.label.visible').remove();

            if (typeof json.error != 'undefined') {
                var messageTemplate = $('.message-skeleton');

                for (var key in json.error) {
                    if (json.error.hasOwnProperty(key)) {
                        var element = json.error[key],
                            clone = messageTemplate.clone();

                        clone.attr('id', 'error-message-' + key);
                        clone.removeClass('invisible message-skeleton');
                        clone.text(element);
                        clone.addClass('visible');
                        if ($('.field.' + key).length) {
                            $('.field.' + key).append(clone);
                        } else {
                            form.find('button[type="submit"]').parent().before(clone);
                        }
                        //clone.css('display', 'flex');
                    }
                }

                formContainer.dimmer('hide');

                form.find('button[type="submit"]').prop('disabled', false);
            } else if (typeof json.redirect != 'undefined') {
                window.location.replace(json.redirect);
            }
        });

        e.preventDefault();
        return false;
    };

    $('#password-reset-form').on('submit', function(e) {
        var passwordElement = $(this).find('#password'),
            notMatcherrorElement = $(this).find('#passwords-not-match'),
            shortErrorElement = $(this).find('#short-password');

        if (passwordElement.val() != $(this).find('#password_repeat').val()) {
            notMatcherrorElement.removeClass('hidden');
            notMatcherrorElement.addClass('visible');

            $(this).find('.login-field.password').addClass('error');
            $(this).find('.login-field.password_repeat').addClass('error');

            e.stopPropagation();

            return false;
        } else {
            notMatcherrorElement.removeClass('visible');
            notMatcherrorElement.addClass('hidden');
        }

        if (passwordElement.val().length < 6) {
            shortErrorElement.removeClass('hidden');
            shortErrorElement.addClass('visible');

            $(this).find('.login-field.password').addClass('error');
            $(this).find('.login-field.password_repeat').addClass('error');

            e.stopPropagation();

            return false;
        } else {
            shortErrorElement.removeClass('visible');
            shortErrorElement.addClass('hidden');
        }

        return true;
    });
})(jQuery);

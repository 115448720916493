(function ($, App) {
    function RequestSendForm(App) {
        this.form = $('#request-send-form');
        this.validator = App.Validator;
        this.errorSkeleton = '<div class="error-label ui basic red pointing below prompt label transition visible"></div>';
        this.fields = {
            name: {
                element: $('#name'),
                isValid: function(validator, element) {
                    return validator.validate(element.val())
                        .required().maxLen(64)
                        .isValid();
                }
            },
            email: {
                element: $('#email'),
                isValid: function(validator, element) {
                    return validator.validate(element.val())
                        .required().email()
                        .isValid();
                }
            },
            phone: {
                element: $('#phone'),
                isValid: function(validator, element) {
                    var mask = element.data('mask');
                    var format = "" + element.data('mask-human');
                    return validator.validate(element.val())
                        .required()
                        .mask(mask, App.t('The format must be: %mask%', { '%mask%': format }))
                        .isValid();
                }
            }
        };
        this.submitButton = this.form.find('.send-request-button');
    }

    RequestSendForm.prototype.isValid = function(name) {
        name = name || '';
        var valid = true;

        if ('' == name) {
            for (var key in this.fields) {
                if (!this.fields[key].isValid(this.validator, this.fields[key].element)) {
                    valid = false;
                    break;
                }
            }
        } else {
            if (this.fields.hasOwnProperty(name)) {
                valid = this.fields[name].isValid(this.validator, this.fields[name].element);
            }
        }

        return valid;
    };

    RequestSendForm.prototype.displayError = function(name) {
        name = name || '';
        if (this.fields.hasOwnProperty(name)) {
            var prompt = this.fields[name].element.parents('.field').find('.error-label');
            if (this.fields[name].isValid(this.validator, this.fields[name].element)) {
                if (prompt.length) {
                    this.fields[name].element.parents('.field').find('.error-label').remove();
                }
            } else {
                var msg = this.validator.getError();
                if (prompt.length) {
                    if (prompt.html() != msg) {
                        prompt.html(msg);
                    }
                } else {
                    var error = this.errorSkeleton;
                    this.fields[name].element.parents('.field').append($(error).html(msg));
                }
            }
        }
    };

    RequestSendForm.prototype.displayErrors = function() {
        for (var key in this.fields) {
            this.displayError(key);
        }
    };

    RequestSendForm.prototype.getData = function() {
        var data = {};
        for (var key in this.fields) {
            data[key] = $('#' + key).val();
        }
        return data;
    };

    RequestSendForm.prototype.submit = function() {
        requestForm.form.addClass('loading');
        $.ajax({
            type: "POST",
            url: requestForm.form.attr('action'),
            dataType: "json",
            data: requestForm.form.serialize(),
            success: function(data) {
                requestForm.form.removeClass('loading');
                switch (data.status) {
                    case 'success':
                        $('.rim-container .forms .form.request-send').addClass('invisible');
                        $('.rim-container .forms .form.request-send-success').removeClass('invisible');
                        requestForm.form.trigger('reset');
                        break;
                    case 'error':
                        $('.rim-container .forms .form.request-send-success').addClass('invisible');
                        if (data.error.messages) {
                            for (var key in data.error.messages) {
                                var item = $('#' + key);
                                if (item.length) {
                                    item.parents('.field').append($(requestForm.errorSkeleton).html(data.error.messages[key]));
                                }
                            }
                        }
                        break;
                }
            },
            error: function(data) {
                requestForm.form.removeClass('loading');
            }
        });
    };

    RequestSendForm.prototype.init = function() {
        if (!this.form.length) {
            return;
        }

        for (var key in requestForm.fields) {
            requestForm.fields[key].element.on('change', function(event){
                requestForm.displayError(event.currentTarget.id);
            });
            requestForm.fields[key].element.on('focusout', function(event){
                $(event.currentTarget).trigger('change');
            });
            requestForm.fields[key].element.on('keyup', function(event){
                if (9 != event.which) {
                    $(event.currentTarget).trigger('change');
                }
            });
        }

        requestForm.submitButton.on('click', function(event){
            if (requestForm.isValid()) {
                requestForm.submit();
            } else {
                requestForm.displayErrors();
            }
        });

    };

    var requestForm = new RequestSendForm(App);
    requestForm.init();

    function CarForm() {
        this.alcarProducts = {};
        this.alcarProductsOrder = [];
        this.productsLoaded = false;
        this.sizes = [];
        this.filteredRims = {};
    }

    //CarForm.prototype.fillFromUserCars = function() {
    //    var carData = $('#car_id option:selected').data();
    //    $('#brand').val(carData.brand).trigger('blur');
    //    $('#type').val(carData.type).trigger('blur');
    //    $('#model').val(carData.model).trigger('blur');
    //    $('#brand_id').val(carData.brand_id);
    //    $('#type_id').val(carData.type_id);
    //    $('#model_id').val(carData.model_id);
    //    $('#image').val(carData.image);
    //    $('.alcar-car-image').addClass('ready');
    //    $('.alcar-car-image .status-image').append(
    //        $('<img>', {
    //            class: 'ui fluid image',
    //            src: carData.image
    //        })
    //    );
    //};

    CarForm.prototype.init = function () {
        if (!$('body').hasClass('page-rim-search')) {
            return;
        }

        $('#rim-search-results').on('click','.rim-search-result .button',function(event) {
            var result = $(event.currentTarget);
            var url = result.data('car-image');
            $('.alcar-car-image .status-image .image').attr('src', url);
            $('#rim-search-results .rim-search-result .button.active').removeClass('active');
            result.addClass('active');
            self.displayProduct(result.data('article-id'));
        });
        $('#brand').dropdown({ fullTextSearch: true });
        $('#type').dropdown({ fullTextSearch: true });
        $('#model').dropdown({ fullTextSearch: true });
        $('#size').dropdown();

        $('#add-to-cart-form .add-to-cart-button').on('click', function(event) {
            var form = $('#add-to-cart-form');
            var input = form.find('.product-quantity-input');
            var quantity = input.val();
            var sku = input.data('sku');
            form.addClass('loading');
            App.Cart.add(sku,quantity,
                function(status, data) {
                    form.removeClass('loading');
                    if (status) {
                        $('.forms .form').addClass('invisible');
                        $('.forms .shop-success').removeClass('invisible');
                    }
                }
            );
        });

        this.loadBrands();

        $('#brand').on('change',function(event) {
            if ($('#brand').val()) {
                self.stepBack('brand');
                self.stepForward('brand');
            }
        });

        $('#type').on('change',function(event) {
            if ($('#type').val()) {
                self.stepBack('type');
                self.stepForward('type');
            }
        });

        $('#model').on('change',function(event) {
            if ($('#model').val()) {
                self.stepBack('model');
                self.stepForward('model');
            }
        });

        $('#size').on('change',function(event) {
            if ($('#size').val()) {
                self.stepBack('size');
                self.stepForward('size');
            }
        });

        var frame = $('.rim-search-results');
        $('#rim-search-results .controls .hs-left-button').on('click', function(event){
            var shift = App.Utils.calculateHorizontalScrollShift(frame);
            App.Utils.scrollLeft(frame, shift, null, frame);
        });

        $('#rim-search-results .controls .hs-right-button').on('click', function(event){
            var shift = App.Utils.calculateHorizontalScrollShift(frame);
            App.Utils.scrollRight(frame, shift, null, frame);
        });

        $('#rim-search-results').on('swiperight', function(event) {
            var shift = App.Utils.calculateHorizontalScrollShift(frame);
            App.Utils.scrollLeft(frame, shift, null, frame);
        }).on('swipeleft', function(event) {
            var shift = App.Utils.calculateHorizontalScrollShift(frame);
            App.Utils.scrollRight(frame, shift, null, frame);
        })
        ;
    };

    CarForm.prototype.stepForward = function(stepName) {
        switch (stepName) {
            case 'brand':
                self.loadTypes();
                break;
            case 'type':
                self.loadModels();
                break;
            case 'model':
                self.loadSizes();
                break;
            case 'size':
                self.loadImage();
                self.loadRims();
                break;
            default: break;
        }
    };

    CarForm.prototype.stepBack = function(stepName) {
        switch (stepName) {
            case 'brand':
                $('#type').empty();
                $('#type').dropdown('restore defaults');
                //break;
            case 'type':
                $('#model').empty();
                $('#model').dropdown('restore defaults');
                //break;
            case 'model':
                $('#size').empty();
                $('#size').dropdown('restore defaults');
                self.sizes = {};
                self.productsLoaded = false;
                self.alcarProducts = [];
                //break;
            case 'size':
                $('.alcar-car-image .status-image').empty();
                $('.alcar-car-image').removeClass('ready');
                this.hideSearchResults();
                //break;
            default: break;
        }
    };

    CarForm.prototype.filter = function() {
        self.filteredRims = [];
        if (!this.productsLoaded) {
            return;
        }
        var size = $('#size').val();
        if (!size) {
            return;
        }

        var key, rim;
        for (var i = 0, len = self.alcarProductsOrder.length; i < len; ++i) {
            key = self.alcarProductsOrder[i];
            if (self.alcarProducts[key]) {
                rim = self.alcarProducts[key];
                if (size == rim.diameter) {
                    self.filteredRims.push(key);
                }
            }
        }
    };

    CarForm.prototype.loadAlcarProducts = function(callback) {
        if (this.productsLoaded) {
            return callback(true);
        }
        var modelCode = $('#model').val();
        if (!modelCode) {
            return callback(false);
        }

        self.alcarProducts = {};
        self.alcarProductsOrder = [];
        self.productsLoaded = true;
        this.sizes = {};
        this.filteredRims = {};

        App.Alcar.getAlcarProducts(modelCode, function (status, alcarProducts) {
            if (!status) {
                return callback(false);
            }

            var articleIds = Object.keys(alcarProducts);
            if (!articleIds) {
                // no articles found
                return callback(true);
            }

            $.ajax({
                type: "POST",
                url: '/ajax/rim-search/get-rims',
                dataType: "json",
                data: { article_ids: articleIds },
                success: function (data) {
                    if (data.success) {
                        var products = data.results;
                        var orders = {
                            'in-stock': [],
                            'low-stock': [],
                            'orderable': [],
                            'out-of-stock': [],
                            'request-to-stock': []
                        };
                        var rim, dimension, size, structure, offset;
                        for (var i = 0, len = articleIds.length; i < len; ++i) {
                            rim = alcarProducts[articleIds[i]];
                            dimension = rim.Dimension.split(' x ');
                            size = dimension[0] * 1 + 'x' + dimension[1] + '"';
                            rim.diameter = dimension[1];
                            rim.size = size;
                            structure = rim.BoltPattern + 'x' + rim.BoltCircle*1;
                            rim.structure = structure;
                            offset = 'ET'+rim.Offset;
                            rim.offset = offset;
                            if (products[articleIds[i]]) {
                                rim['Product'] = products[articleIds[i]];
                                rim.stock_class = rim.Product.stock_class;
                                rim.stock_icon = rim.Product.stock_icon;
                                orders[rim.stock_class].push(articleIds[i]);
                            } else {
                                rim['Product'] = false;
                                rim.stock_class = 'request-to-stock';
                                rim.stock_icon = 'help';
                                orders['request-to-stock'].push(articleIds[i]);
                            }
                            self.alcarProducts[articleIds[i]] = rim;
                        }
                        self.alcarProductsOrder = [].concat(
                            orders['in-stock'],
                            orders['low-stock'],
                            orders['orderable'],
                            orders['out-of-stock'],
                            orders['request-to-stock']
                        );
                    }
                    callback(true);
                },
                error: function(data) {
                    callback(false);
                }
            });
        });
    };

    CarForm.prototype.displayProduct = function(articleId) {
        var item = self.alcarProducts[articleId];
        var rim = $('.rim-container');
        rim.find('.image img').attr('src', item.Image);
        rim.find('.manufacturer-name').html(item.Brand);
        rim.find('.tread-name').html(item.Design + ' - ' + item.ColourText);
        rim.find('.size').text(item.size);
        rim.find('.structure').text(item.structure);
        rim.find('.offset').text(item.offset);
        $('.info-message .form').addClass('invisible');
        if ('request-to-stock' == item.stock_class) {
            $('.info-message .form.request-send').removeClass('invisible');
        } else {
            var form = $('#add-to-cart-form');
            form.find('.retail-price-brutto').html(item.Product.price_formatted);
            form.find('.quantity-wrapper .quantity')
                .attr('id', 'quantity-'+item.Product.sku)
                .attr('name','quantity['+item.Product.sku+']')
                .attr('data-sku', item.Product.sku)
            ;
            form.find('.stockinfo-text').html(item.Product.stock_text);
            form.find('.storage-status')
                .attr('data-title', item.Product.stock_text)
                .attr('data-html', item.Product.stock_description)
                .removeClass('in-stock low-stock out-of-stock')
                .addClass(item.stock_class)
            ;
            form.find('.storage-status .icon')
                .removeClass('help check warning remove')
                .addClass(item.stock_icon)
            ;

            $('.info-message .form.shop').removeClass('invisible');
        }
        $('.rim-container .forms .form').addClass('invisible');

        if ('request-to-stock' == item.stock_class) {
            $('.rim-container .forms .form.request-send').removeClass('invisible');
            $('.rim-container .forms .form.request-send').find('#article_id').val(articleId);
        } else {
            $('.rim-container .forms .form.shop').removeClass('invisible');
        }
        $('.car-image-section .empty-message').addClass('invisible');
        rim.removeClass('invisible');
    };

    CarForm.prototype.displaySearchResults = function() {
        if (self.filteredRims) {
            $('#rim-search-results .empty-message').addClass('invisible');
            $('#rim-search-results .search-results-panel').removeClass('invisible');
            var displayContainer = $('#rim-search-results .rim-search-results');
            displayContainer.empty();
            var skeleton = $('#skeletons .skeleton.rim-search-result').clone();
            skeleton.removeClass('skeleton');
            for (var i = 0, len = self.filteredRims.length; i < len; ++i) {
                var item = self.alcarProducts[self.filteredRims[i]];
                var rim = skeleton.clone();
                rim.find('.storage-status').addClass(item.stock_class).attr('data-html', item.stock_description);
                rim.find('.storage-status .icon').addClass(item.stock_icon);
                rim.find('.button')
                    .attr('data-car-image', item.ImageOnCarBinary)
                    .attr('data-article-id', item.Article);
                rim.find('.size').text(item.size);
                rim.find('.structure').text(item.structure);
                rim.find('.offset').text(item.offset);
                rim.find('img.image').attr('src', item.Image);
                displayContainer.append(rim);
            }
        }
    };

    CarForm.prototype.hideSearchResults = function() {
        $('#rim-search-results .rim-search-results').empty();
        $('#rim-search-results .empty-message').removeClass('invisible');
        $('#rim-search-results .search-results-panel').addClass('invisible');
        $('.car-image-section .empty-message').removeClass('invisible');
        $('.rim-container').addClass('invisible');
        $('.info-message .form').addClass('invisible');
    };

    CarForm.prototype.loadRims = function() {
        this.filter();
        this.displaySearchResults();
    };

    CarForm.prototype.loadImage = function() {
        $('.alcar-car-image .status-image').empty();
        $('.alcar-car-image').removeClass('ready');
        $('.alcar-car-image').addClass('waiting');

        var keys = Object.keys(self.alcarProducts);
        for (var i = 0, len = keys.length; i < len; ++i) {
            var model = self.alcarProducts[keys[i]];
            if (model.ImageOnCarBinary) {
                $('.alcar-car-image').addClass('ready');
                $('.alcar-car-image .status-image').append(
                    $('<img>', {
                        class: 'ui fluid image',
                        src: model.ImageOnCarBinary
                    })
                );
                $('#image').val(model.ImageOnCarBinary);

                break;
            }
        }
        $('.alcar-car-image').removeClass('waiting');
    };

    CarForm.prototype.loadSizes = function() {
        $('.alcar-size-field .input').addClass('loading');
        self.sizes = [];
        this.loadAlcarProducts(function(success) {
            if (!success) {
                $('.alcar-size-field .input').removeClass('loading');
                return;
            }
            self.sizes = App.Alcar.getRimSizes(self.alcarProducts, false);
            var size;
            for (var i = 0, len = self.sizes.length; i < len; ++i) {
                size = self.sizes[i];
                $('#size').append('<option value="'+size+'">'+size+' ' + App.t('coll') + '</option>');
            }
            $('.alcar-size-field .input').removeClass('loading');
            $('#size').dropdown('restore defaults');
        });
    };

    CarForm.prototype.loadModels = function() {
        var brandCode = $('#brand').val();
        var typeCode = $('#type').val();
        if (!brandCode || !typeCode) {
            // if missing brand or type code, unable to load anything
            return;
        }
        $('.alcar-model-field .input').addClass('loading');
        App.Alcar.getModels(brandCode, typeCode, function(status, models){
            if (status) {
                $.each(models, function (i, item) {
                    $('#model').append('<option value="'+item.ModelCode+'">'+item.ModelName+'</option>');
                });
            }
            $('.alcar-model-field .input').removeClass('loading');
            $('#model').dropdown('restore defaults');
        });
    };

    CarForm.prototype.loadTypes = function() {
        var brandCode = $('#brand').val();
        if (!brandCode) {
            // if not brand code, unable to load anything
            return;
        }
        $('.alcar-type-field .input').addClass('loading');
        App.Alcar.getTypes(brandCode, function(status, types){
            if (status) {
                $.each(types, function (i, item) {
                    $('#type').append('<option value="'+item.TypeCode+'">'+item.TypeText+'</option>');
                });
            }
            $('.alcar-type-field .input').removeClass('loading');
            $('#type').dropdown('restore defaults');
        });
    };

    CarForm.prototype.loadBrands = function() {
        $('.alcar-brand-field .input').addClass('loading');
        App.Alcar.getBrands(function(status, brands){
            $('.alcar-brand-field .input').removeClass('loading');
            if (status) {
                $.each(brands, function (i, item) {
                    $('#brand').append('<option value="'+item.BrandCode+'">'+item.BrandText+'</option>');
                });
            }
        });
    };

    var self = new CarForm();
    self.init();

})(jQuery, App);

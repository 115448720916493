(function (App, $) {

    // cart exit intent coupon

    var modal = $('#cart-exit-intent-modal');
    if (0 == modal.length) {
        return;
    }

    var refused = false;
    var cookieName = modal.data('cookie_name');
    var cookieExpires = modal.data('cookie_expires');
    var validCoupon = {};

    if (App.Storage.get(cookieName)) {
        return;
    }

    modal.modal({
        closable: false,
        onShow: function() {
            var price = validCoupon.price;
            if ('-' == price.substr(0,1)) {
                price = price.substr(1);
            }
            modal.find('.price').html(price);
        }
    });
    modal.dimmer({
        closable: false
    });

    var coupons = modal.data('products');
    var validTypes = modal.data('valid_types');
    var reloadUrl = modal.data('reload-url');

    // starting event
    $(document).on('mouseleave', function(event) {
        if (!refused && 20 > event.clientY) {
            validCoupon = {};
            // check cart
            var products = App.Cart.items;
            var count = 0;

            if (products) {
                var key;
                for (key in products) {
                    if (2 < count) {
                        break;
                    }
                    if ('coupon' == products[key]['type']) {
                        continue;
                    }

                    if (!validTypes || (0 == validTypes.length)) {
                        count += products[key]['quantity'];
                        continue;
                    }
                    if (products[key]['type']) {
                        if (-1 < validTypes.indexOf(products[key]['type'])) {
                            count += products[key]['quantity'];
                        }
                    }
                }
            }

            if (1 > count) {
                return;
            }
            validCoupon = coupons.c_cei500;
            if (2 < count) {
                validCoupon = coupons.c_cei1000;
            }
            modal.find('.success-box').addClass('invisible');
            modal.find('.answer-box').removeClass('invisible');
            document.getElementById('cart-exit-intent-modal-form').reset();
            modal.modal('show');
        }
    });

    // answer form
    $('.answer-box .answer').on('change', modal, function(event) {
        var input = $('#custom-answer-textarea');
        var checkbox = $(event.currentTarget);
        var customCheckbox = modal.find('.answer-box .custom-answer');
        if (customCheckbox.prop('checked')) {
            input.find('.custom-answer-input').val('');
            input.removeClass('invisible');
        } else {
            input.addClass('invisible');
            input.find('.custom-answer-input').val(checkbox.data('answer'));
        }
    });

    // send answer button
    $('.send-answer-button').on('click', modal, function(event) {
        var answer = $('#custom-answer-textarea .custom-answer-input').val();
        var customCheckbox = modal.find('.answer-box .custom-answer');
        var customAnswer = false;
        if (customCheckbox.prop('checked')) {
            customAnswer = true;
        }

        // validate
        if (answer.length < 1) {
            App.Messages.add(modal.data('form-empty'), 'error');
            return;
        }

        modal.dimmer('show');

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: modal.data('url'),
            data: {
                reason: answer,
                custom_reason: customAnswer,
                coupon_code: validCoupon.coupon_code
            }
        })
        .done(function(data) {
            if ('success' == data.status) {
                modal.find('.answer-box').addClass('invisible');
                modal.find('.success-box').removeClass('invisible');
            } else {
                for (var len = data.errors.length - 1; 0 <= len; len--) {
                    App.Messages.add(data.errors[len], 'error', 10);
                }
            }
        })
        .fail(function(data) {
            App.Messages.add(modal.data('fail'),'error', 10);
        })
        .always(function() {
            modal.dimmer('hide');
        })
        ;
    });

    // modal close button
    $('.modal-close-button').on('click', modal, function(event) {
        App.Storage.set(cookieName, 'refused', { expires: cookieExpires });
        refused = true;
        modal.modal('hide');
    });

    // continue shopping button
    $('.continue-shopping-button').on('click', modal, function(event) {
        modal.modal('hide');
        window.location.replace(reloadUrl);
    });

})(App, jQuery);

(function (App, $) {

    // Goodyear - Lukoil promo
    var form = $('#goodyear-lukoil-promo-coupon-form');
    if (0 == form.length) {
        return;
    }

    var errorSkeleton = '<div class="error-label ui basic red pointing below prompt label transition visible"></div>';
    var button = $('#goodyear-lukoil-coupon-button');
    var input = $('#goodyear-lukoil-coupon-code');

    function validateInput() {
        if (App.Validator.validate(input.val()).equalLen(8).isValid()) {
            removeError();
            return true;
        }
        showError(App.Validator.getError());
        return false;
    }

    function removeError() {
        var prompt = input.parents('.field').find('.error-label');
        if (prompt.length) {
            prompt.remove();
        }
    }

    function showError(msg) {
        var prompt = input.parents('.field').find('.error-label');
        if (prompt.length) {
            if (prompt.html() != msg) {
                prompt.html(msg);
            }
        } else {
            input.parents('.field').append($(errorSkeleton).html(msg));
        }
    }

    function sendCouponCode() {
        input.parents('.field').find('.dimmer').addClass('active');
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: button.data('url'),
            data: {
                coupon_code: input.val()
            }
        })
        .done(function(data) {
            if ('success' == data.status) {
                window.location.reload(true);
            } else {
                for (var len = data.errors.length - 1; 0 <= len; len--) {
                    App.Messages.add(data.errors[len], 'error', 10);
                }
            }
        })
        .fail(function(data) {
        })
        .always(function() {
            input.parents('.field').find('.dimmer').removeClass('active');
        });
    }

    input.on('change', function(event) {
        validateInput();
    }).on('keyup', function(event) {
        validateInput();
    });

    button.on('click', function(event) {
        if (!validateInput()) {
            return;
        }
        sendCouponCode();
    });

    App.Cart.load(function() {
        if (App.Cart.items) {
            $.each(App.Cart.items, function (k, item) {
               if (item.coupon) {
                   if ('glp' == item.coupon.coupon_type) {
                        form.hide();
                   }
               }
            });
        }
    });

})(App, jQuery);

(function (App, $) {

    // Rabocsi - Matador coupon
    var form = $('#rabocsi-matador-coupon-form');

    if (0 == form.length) {
        return;
    }

    var errorSkeleton = '<div class="error-label ui basic red pointing below prompt label transition visible"></div>';
    var button = $('#rabocsi-matador-coupon-button');
    var input = $('#rabocsi-matador-coupon-code');

    function validateInput() {
        if (App.Validator.validate(input.val()).equalLen(8).isValid()) {
            removeError();
            return true;
        }
        showError(App.Validator.getError());
        return false;
    }

    function removeError() {
        var prompt = input.parents('.field').find('.error-label');
        if (prompt.length) {
            prompt.remove();
        }
    }

    function showError(msg) {
        var prompt = input.parents('.field').find('.error-label');
        if (prompt.length) {
            if (prompt.html() != msg) {
                prompt.html(msg);
            }
        } else {
            input.parents('.field').append($(errorSkeleton).html(msg));
        }
    }

    function sendCouponCode() {
        input.parents('.field').find('.dimmer').addClass('active');
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: button.data('url'),
            data: {
                coupon_code: input.val()
            }
        })
            .done(function(data) {
                if ('success' == data.status) {
                    window.location.reload(true);
                } else {
                    for (var len = data.errors.length - 1; 0 <= len; len--) {
                        App.Messages.add(data.errors[len], 'error', 10);
                    }
                }
            })
            .fail(function(data) {
            })
            .always(function() {
                input.parents('.field').find('.dimmer').removeClass('active');
            });
    }

    input.on('change', function(event) {
        validateInput();
    }).on('keyup', function(event) {
        validateInput();
    });

    button.on('click', function(event) {
        if (!validateInput()) {
            return;
        }
        sendCouponCode();
    });

    App.Cart.load(function() {
        if (App.Cart.items) {
            $.each(App.Cart.items, function (k, item) {
                if (item.coupon) {
                    if ('rm' == item.coupon.coupon_type) {
                        form.hide();
                    }
                }
            });
        }
    });

})(App, jQuery);

(function (App, $) {

    // Goodyear 2020 winter
    var form = $('#goodyear-2020-winter-coupon-form');

    if (0 == form.length) {
        return;
    }

    var errorSkeleton = '<div class="error-label ui basic red pointing below prompt label transition visible"></div>';
    var button = $('#goodyear-2020-winter-coupon-button');
    var input = $('#goodyear-2020-winter-coupon-code');

    function validateInput() {
        if (App.Validator.validate(input.val()).equalLen(6).isValid()) {
            removeError();
            return true;
        }
        showError(App.Validator.getError());
        return false;
    }

    function removeError() {
        var prompt = input.parents('.field').find('.error-label');
        if (prompt.length) {
            prompt.remove();
        }
    }

    function showError(msg) {
        var prompt = input.parents('.field').find('.error-label');
        if (prompt.length) {
            if (prompt.html() != msg) {
                prompt.html(msg);
            }
        } else {
            input.parents('.field').append($(errorSkeleton).html(msg));
        }
    }

    function sendCouponCode() {
        input.parents('.field').find('.dimmer').addClass('active');
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: button.data('url'),
            data: {
                coupon_code: input.val()
            }
        })
            .done(function(data) {
                if ('success' == data.status) {
                    window.location.reload(true);
                } else {
                    for (var len = data.errors.length - 1; 0 <= len; len--) {
                        App.Messages.add(data.errors[len], 'error', 10);
                    }
                }
            })
            .fail(function(data) {
            })
            .always(function() {
                input.parents('.field').find('.dimmer').removeClass('active');
            });
    }

    input.on('change', function(event) {
        validateInput();
    }).on('keyup', function(event) {
        validateInput();
    });

    button.on('click', function(event) {
        if (!validateInput()) {
            return;
        }
        sendCouponCode();
    });

    App.Cart.load(function() {
        if (App.Cart.items) {
            $.each(App.Cart.items, function (k, item) {
                if (item.coupon) {
                    if ('gy4000' == item.coupon.coupon_type) {
                        form.hide();
                    }
                }
            });
        }
    });

})(App, jQuery);

(function (App, $) {

    // basic coupon forms
    var forms = $('.basic-coupon-form');
    if (0 === forms.length) {
        return;
    }
    var errorSkeleton = '<div class="error-label ui basic red pointing below prompt label transition visible"></div>';

    function removeError(input) {
        var prompt = input.parents('.field').find('.error-label');
        if (prompt.length) {
            prompt.remove();
        }
    }

    function showError(input, msg) {
        var prompt = input.parents('.field').find('.error-label');
        if (prompt.length) {
            if (prompt.html() !== msg) {
                prompt.html(msg);
            }
        } else {
            input.parents('.field').append($(errorSkeleton).html(msg));
        }
    }

    function validateInput(input) {
        var el = input.data('equalLen');
        if (el) {
            if (App.Validator.validate(input.val()).equalLen(el).isValid()) {
                removeError(input);
                return true;
            }
        } else {
            if (App.Validator.validate(input.val()).required().isValid()) {
                removeError(input);
                return true;
            }
        }

        showError(input, App.Validator.getError());
        return false;
    }

    function sendCouponCode(input, button) {
        input.parents('.field').find('.dimmer').addClass('active');
        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: button.data('url'),
            data: {
                coupon_code: input.val()
            }
        })
        .done(function(data) {
            if ('success' === data.status) {
                window.location.reload(true);
            } else {
                for (var len = data.errors.length - 1; 0 <= len; len--) {
                    App.Messages.add(data.errors[len], 'error', 10);
                }
            }
        })
        .fail(function(data) {
        })
        .always(function() {
            input.parents('.field').find('.dimmer').removeClass('active');
        });
    }

    function initForm(form) {
        var input = form.find('input[name=coupon_code]');
        input.on('change', function() {
            validateInput(input);
        }).on('keyup', function() {
            validateInput(input);
        });

        var button = form.find('button[type=button]');
        button.on('click', function() {
            if (!validateInput(input)) {
                return;
            }
            sendCouponCode(input, button);
        });

        var couponType = input.data('type');
        App.Cart.load(function() {
            if (App.Cart.items) {
                $.each(App.Cart.items, function (k, item) {
                    if (item.coupon) {
                        if (couponType === item.coupon.coupon_type) {
                            form.hide();
                        }
                    }
                });
            }
        });
    }

    // init all
    forms.each((item) => {
        initForm($(forms[item]));
    });

})(App, jQuery);


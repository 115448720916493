
(function (App, $) {

    $('.goto-down').on('click',function(event){
        var toElement = $(event.currentTarget);
        App.Utils.scrollTo(toElement, -60);
    });

    var simpleHeader = $('#page-header .header-simple');
    if (simpleHeader.length) {
        simpleHeader.find('a').each(function() {
                $(this).prop('href', $(this).prop('href') + '?dhr=yes');
            }
        );
    }

})(App, jQuery);


(function (App, $) {

    // desktop version
    var mobile = $('html.mobile-layout').length;
    var productsSection = $('#discounts-discount-products-section');

    if (0 == productsSection.length || mobile) {
        return;
    }

    var dimmable = $('#discount-products-section-wrapper');
    var discountId = productsSection.data('discount-id');
    var ajaxUrl = productsSection.data('url');

    dimmable.dimmer('show');
    productsSection.load(
        ajaxUrl,
        {
            discount_id: discountId
        }, function() {
            $('.ordering-select').dropdown();
            setTimeout(function() { dimmable.dimmer('hide'); }, 200);
        }
    );

    productsSection.on('click','.pager > a', function (event) {
        var params = '';
        var link = $(event.currentTarget);

        event.preventDefault();
        if (link.hasClass('active')) {
            return;
        }

        var url = link.prop('href');
        var found = url.indexOf('?');
        if (-1 !=found) {
            params = url.slice(found);
        }

        var form = $('#discount-products-form');
        var data = [];
        if (form) {
            data = form.serializeArray();
        }
        data.push({ name: 'discount_id', value: discountId });

        App.Utils.scrollTo(productsSection, -50, 500);
        dimmable.dimmer('show');
        productsSection.load(
            ajaxUrl + params,
            data,
            function() {
                $('.ordering-select').dropdown();
                setTimeout(function() { dimmable.dimmer('hide'); }, 200);
            }
        );
    });

    productsSection.on('click','.ordering-select .menu > a', function (event) {
        var params = '';
        var link = $(event.currentTarget);
        event.preventDefault();

        var url = link.prop('href');
        var found = url.indexOf('?');
        if (-1 !=found) {
            params = url.slice(found);
        }
        var form = $('#discount-products-form');
        var data = [];
        if (form) {
            data = form.serializeArray();
        }
        data.push({ name: 'discount_id', value: discountId });

        App.Utils.scrollTo(productsSection, -50, 500);
        dimmable.dimmer('show');
        productsSection.load(
            ajaxUrl + params,
            data,
            function() {
                $('.ordering-select').dropdown();
                setTimeout(function() { dimmable.dimmer('hide'); }, 200);
            }
        );
    });

    productsSection.on('change', '#discount-products-form', function(event) {
        var form = $(event.currentTarget);
        var action = form.prop('action');
        var data = form.serializeArray();
        data.push({ name: 'discount_id', value: discountId });

        App.Utils.scrollTo(productsSection, -50, 500);
        dimmable.dimmer('show');
        productsSection.load(
            action,
            data,
            function() {
                $('.ordering-select').dropdown();
                setTimeout(function() { dimmable.dimmer('hide'); }, 200);
            }
        );
    });

})(App, jQuery);

(function (App, $) {

    // mobile version
    var mobile = $('html.mobile-layout').length;
    var productsSection = $('#discounts-discount-products-section');

    if (0 == productsSection.length || !mobile) {
        return;
    }

    var dimmable = $('#discount-products-section-wrapper');
    var discountId = productsSection.data('discount-id');
    var ajaxUrl = productsSection.data('url');

    dimmable.dimmer('show');
    productsSection.load(
        ajaxUrl,
        {
            discount_id: discountId
        }, function() {
            $('.ordering-select').dropdown();
            setTimeout(function() { dimmable.dimmer('hide'); }, 200);
        }
    );

    productsSection.on('click','.pager > a', function (event) {
        var params = '';
        var link = $(event.currentTarget);

        event.preventDefault();
        if (link.hasClass('active')) {
            return;
        }

        var url = link.prop('href');
        var found = url.indexOf('?');
        if (-1 !=found) {
            params = url.slice(found);
        }
        var form = $('#discount-products-form');
        var data = [];
        if (form) {
            data = form.serializeArray();
        }
        data.push({ name: 'discount_id', value: discountId });

        App.Utils.scrollTo(productsSection, -50, 500);
        dimmable.dimmer('show');
        productsSection.load(
            ajaxUrl + params,
            data,
            function() {
                $('.ordering-select').dropdown();
                setTimeout(function() { dimmable.dimmer('hide'); }, 200);
            }
        );
    });

    productsSection.on('click','.ordering-select .menu > a', function (event) {
        var params = '';
        var link = $(event.currentTarget);
        event.preventDefault();

        var url = link.prop('href');
        var found = url.indexOf('?');
        if (-1 !=found) {
            params = url.slice(found);
        }
        var form = $('#discount-products-form');
        var data = [];
        if (form) {
            data = form.serializeArray();
        }
        data.push({ name: 'discount_id', value: discountId });

        App.Utils.scrollTo(productsSection, -50, 500);
        dimmable.dimmer('show');
        productsSection.load(
            ajaxUrl + params,
            data,
            function() {
                $('.ordering-select').dropdown();
                setTimeout(function() { dimmable.dimmer('hide'); }, 200);
            }
        );
    });

    productsSection.on('change', '#discount-products-form', function(event) {
        var changed = $(event.target);
        var sidebarForm = $('#discount-products-form');

        if (changed.hasClass('width') || changed.hasClass('profile') || changed.hasClass('diameter')) {
            // remove size
            sidebarForm.find('.sizes').remove();
        }
        var refreshUrl = sidebarForm.data('refresh-url');
        var formData = sidebarForm.serializeArray();
        var button = $('.detailed-search-panel .show-results');
        var ids = sidebarForm.data('id');
        if (ids) {
            ids = ids.split(',');
            formData.push({name: 'id', value: ids});
        }

        sidebarForm.find('.search-status').addClass('visible');
        button.addClass('loading');
        $.ajax({
            url: refreshUrl,
            data: formData,
            dataType: 'json',
            method: 'post'
        }).done(function(data){
            button.removeClass('loading');
            if (data.success) {
                button.find('.found-counter').text(data.results.total);
                refreshOptions(data.results.form_elements, data.results.options);
            }
        });
    });

    productsSection.on('click', '.show-results', function(event) {
        var form = $('#discount-products-form');
        var action = form.prop('action');
        var data = form.serializeArray();
        data.push({ name: 'discount_id', value: discountId });

        App.Utils.scrollTo(productsSection, -50, 500);
        dimmable.dimmer('show');
        productsSection.load(
            action,
            data,
            function() {
                $('.ordering-select').dropdown();
                setTimeout(function() { dimmable.dimmer('hide'); }, 200);
            }
        );
    });

    productsSection.on('click', '.show-filter-panel', function(event) {
        $('.floated-form-panel').addClass('active');
        $('.search-status').addClass('open');
        App.Utils.scrollTo(productsSection, -50, 800);
    });

    productsSection.on('click', '.hide-filter-panel', function(event) {
        $('.floated-form-panel').removeClass('active');
        $('.search-status').removeClass('open');
    });

    function refreshCheckbox(key, values) {
        if (!Array.isArray(values)) {
            values = [ values ];
        }
        $('#discount-products-form').find('.checkbox-field .'+key).each(function(index, elem){
            elem = $(elem);
            if (-1 == $.inArray(elem.prop('value'), values)) {
                elem.prop('disabled', true);
                elem.parents('.checkbox-field').addClass('disabled');
            } else {
                elem.prop('disabled', false);
                elem.parents('.checkbox-field').removeClass('disabled');
            }
        });
    }

    function refreshSelect(key, values) {
        if (!Array.isArray(values)) {
            values = [ values ];
        }
        $('#discount-products-form').find('.select-field .'+key+' option').each(function(index, elem){
            elem = $(elem);
            var value = elem.prop('value');
            if (value) {
                if (-1 == $.inArray(value, values)) {
                    elem.addClass('invisible');
                } else {
                    elem.removeClass('invisible');
                }
            }
        });
    }

    function refreshOptions(elements, options) {
        $.each(elements, function(_, key) {
            var value = [];
            switch (key) {
                case 'manufacturer_id':
                case 'season':
                case 'car_type':
                case 'run_flat':
                case 'reinforced_version':
                case 'rim_fringe_protector':
                case 'fuel_efficiency':
                case 'wet_grip_class':
                case 'noise_measurement_class':
                case 'machine_type':
                case 'tyre_type':
                    if (options[key]) {
                        value = options[key];
                    }
                    refreshCheckbox(key, value);
                    break;
                case 'width':
                case 'profile':
                case 'diameter':
                case 'holes':
                case 'bolt_pattern':
                case 'wheel_offset':
                    if (options[key]) {
                        value = options[key];
                    }
                    refreshSelect(key, value);
                    break;
                default: break;
            }
        });
    }

})(App, jQuery);

(function ($, App) {

    var cartRail = $('#cart-rail');
    var cartTrain = $('#cart-train');
    if (cartRail.length && cartTrain.length) {
        var scroller = new Scroller($('#cart-rail'), $('#cart-train'));
        scroller.addCallback('getStop', function () {
            return ((scroller.rail.outerHeight() + scroller.railOffset.top) - scroller.train.outerHeight()) - scroller.preStop;
        });
        scroller.run(App.ScrollerManager);
    }

})(jQuery, App);

(function (App, $) {

    var Cart = App.Cart || {};

    Cart.addEvents = function (parent) {
        parent = parent || '#cart .cart-products';

        $(parent).on('click', '.remove-button', function (event) {
            var panel = $(event.currentTarget).parents('.product');
            var input = panel.find('.product-quantity-input');
            var key = input.data('key');
            App.Cart.remove(key, function (status) {
                if (status) {
                    var locale = input.data('locale');
                    var currency = input.data('currency');
                    if (0 == App.Cart.count()) {
                        window.location.reload(true);
                        return;
                    }
                    panel.parent().remove();
                    $('.order-summary .subtotal-price .value').text(App.Cart.getSubtotal(true, currency, locale));
                    $('.order-summary .shipping .value').text(App.Cart.getShippingPrice(true, currency, locale));
                    $('.order-summary .total-price .value').text(App.Cart.getTotal(true, currency, locale));
                } else {
                    // remove failed
                    panel.dimmer('hide');
                }
                $('#cart-goto-next-step-button').prop('disabled', false);
            });
            // block the box
            panel.dimmer(
                {
                    closable: false,
                    template: {
                        dimmer: function () {
                            html = '<div class="ui dimmer">' +
                                '<div class="content">' +
                                '   <div class="center">' +
                                '     <i class="notched circle loading huge icon"></i>' +
                                '   </div>' +
                                '</div>' +
                                '</div>';

                            return html;
                        }
                    }
                }
            ).dimmer('show');
            // block the button
            $('#cart-goto-next-step-button').prop('disabled', true);
        });

        $(parent).on('change', '.product-quantity-input', function (event) {
            // cart quantity update
            var input = $(event.currentTarget);
            var panel = input.parents('.product');
            var key = input.data('key');
            var originalQuantity = App.Cart.getItemQuantity(key);
            var quantity = input.val();
            var itemCount = App.Cart.itemCount();

            App.Cart.update(key, quantity, function (status) {
                if (status) {
                    var unitPrice = input.data('unit-price');
                    var locale = input.data('locale');
                    var currency = input.data('currency');
                    var displaySubTotal = panel.find('.sub-total-price');
                    displaySubTotal.text(App.Utils.formatCurrency(quantity * unitPrice, currency, locale));
                    $('.order-summary .subtotal-price .value').text(App.Cart.getSubtotal(true, currency, locale));
                    $('.order-summary .shipping .value').text(App.Cart.getShippingPrice(true, currency, locale));
                    $('.order-summary .total-price .value').text(App.Cart.getTotal(true, currency, locale));
                    // refresh another products prices if need
                    for (key in App.Cart.items) {
                        if (typeof App.Cart.items[key].coupon !== 'undefined') {
                            if ($('#'+key+' .retail-price').length) {
                                var retailPrice = App.Utils.formatCurrency(App.Cart.items[key].unitPrice, currency, locale);
                                if ($('#'+key+' .retail-price')[0].innerText !== retailPrice) {
                                    $('#'+key+' .retail-price')[0].innerText = retailPrice;
                                }
                            }
                            if ($('#'+key+' .sub-total-price').length) {
                                var subTotalPrice = App.Utils.formatCurrency(App.Cart.items[key].unitPrice * App.Cart.items[key].quantity, currency, locale);
                                if ($('#'+key+' .sub-total-price')[0].innerText !== subTotalPrice) {
                                    $('#'+key+' .sub-total-price')[0].innerText = subTotalPrice;
                                }
                            }
                        }
                    }
                } else {
                    // set back to original value
                    input.val(originalQuantity);
                }
                if (itemCount != App.Cart.itemCount()) {
                    window.location.reload(true);
                    return;
                }
                panel.dimmer('hide');
                $('#cart-goto-next-step-button').prop('disabled', false);
            });
            // block the box
            panel.dimmer(
                {
                    closable: false,
                    template: {
                        dimmer: function () {
                            html = '<div class="ui dimmer">' +
                                '<div class="content">' +
                                '   <div class="center">' +
                                '     <i class="notched circle loading huge icon"></i>' +
                                '   </div>' +
                                '</div>' +
                                '</div>';

                            return html;
                        }
                    }
                }
            ).dimmer('show');
            // block the button
            $('#cart-goto-next-step-button').prop('disabled', true);
        });

        $(parent).on('click', '.radio-label.go-to-booking-switch .on', function (event) {
            var icon = $(event.currentTarget);
            var input = icon.parents('.go-to-booking-switch').find('.go-to-booking');
            if (input.prop('checked')) {
                var radios = document.getElementsByClassName('go-to-booking');
                for (var i = 0, len = radios.length; i < len; i++) {
                    radios[i].checked = false;
                }
            }
            event.preventDefault();
        });
    };

    App.Cart = Cart;

    App.Cart.addEvents();

})(App, jQuery);

(function(App, $) {
    'use strict';

    var UserCar = function() {
        // variables
        this.default = false;
        this.storageName = 'user-car-default';
        this.cars = false;

        // this will lazy load user cars and set default car on first run
        // no further run needed when default cookie is set
        this.__checkDefault();
    };

    UserCar.prototype.__loadCars = function(callBack) {
        var self = this;

        $.ajax({
            type: 'GET',
            url: '/ajax/user-cars/my-cars-loader',
            success: function(cars) {
                self.cars = cars;
                if (typeof callBack === 'function') {
                    callBack(self, cars);
                }
            }
        });
    };

    /**
     * Async load user cars
     * Define callback function which will get usercars as a parameter
     * @param {function} callBack
     * @returns {undefined}
     */
    UserCar.prototype.getCars = function(callBack) {
        if (typeof callBack !== 'function') {
            return;
        }

        if (!this.cars) {
            this.__loadCars(function(selfObj, cars) {
                callBack(cars);
            });
        } else {
            callBack(this.cars);
        }
    };

    /**
     * @param {object} car a full object from one of the user's car
     * @returns {void}
     */
    UserCar.prototype.setDefault = function(car) {
        car = car || false;

        if (car) {
            App.Storage.set(this.storageName, car);
        } else {
            App.Storage.remove(this.storageName);
        }

        this.default = car;
    };

    UserCar.prototype.__checkDefault = function() {
        if (!this.default) {
            if (App.Storage.get(this.storageName)) {
                this.default = App.Storage.get(this.storageName);
            } else if (!this.cars) {
                this.__loadCars(function(userCarsObj, cars) {
                    userCarsObj.setDefault(cars.length ? cars[Object.keys(cars)[0]] : false);
                });
            } else {
                this.setDefault(this.cars.length ? this.cars[Object.keys(this.cars)[0]] : false);
            }
        }
    };

    /**
     * @returns {object|Boolean|@var;car}
     */
    UserCar.prototype.getDefault = function() {
        this.__checkDefault();

        return this.default;
    };

    App.UserCar = new UserCar();
    App.UserCar.__class = UserCar;
})(App, jQuery);

(function (App, $) {
    var MarsoMaps = function (mapDomId, mapguiDomId) {
        var _self = this,
            _rootPath = '/theme/marso/assets/javascripts/',
            mapDomId = mapDomId || 'map',
            mapguiDomId = mapguiDomId || 'map-gui';

        if (!$(`#${mapDomId}`).length) {
            return;
        }

        // get country
        this.country = $('html').attr('lang').split('-')[0];

        _scriptFilename = 'maps.js';
        scripts = document.getElementsByTagName("script");

        for (i = 0; i < scripts.length; i++) {
            if (scripts[i].src && scripts[i].src.indexOf(_scriptFilename) > -1) {
                _rootPath = scripts[i].src.split(_scriptFilename)[0];

                break;
            }
        }

        this.locationIcon = new google.maps.Marker({
            size: new google.maps.Size(45.0, 55.0),
            anchor: new google.maps.Point(27.5, 35.0),
            url: _rootPath + '../images/location.png'
        });
        this.marsoIcon = new google.maps.Marker({
            size: new google.maps.Size(45.0, 55.0),
            anchor: new google.maps.Point(27.5, 35.0),
            url: _rootPath + '../images/wrench-location.png'
        });
        this.partnerIcon = new google.maps.Marker({
            size: new google.maps.Size(45.0, 55.0),
            anchor: new google.maps.Point(27.5, 35.0),
            url: _rootPath + '../images/wheel-location.png'
        });

        // infowindow popup objects
        this.infowindow = new SnazzyInfoWindow({
            closeWhenOthersOpen: true,
            edgeOffset: {
                top: 15 // + 15 infowindow si-close-button
            }
        });

        // Create a map object and specify the DOM element for display.
        this._map = new google.maps.Map(document.getElementById(mapDomId), {
            center: { lat: 47.4811282, lng: 18.9902216 }, // default to Budapest
            scrollwheel: false,
            zoom: 9,
            mapTypeControl: false,
            rotateControl: false,
            fullscreenControl: false
        });

        // load map style
        $.getJSON(_rootPath + 'mapstyle.json', function (style) {
            // Associate the styled map with the MapTypeId and set it to display.
            _self._map.mapTypes.set('styled_map', new google.maps.StyledMapType(style));
            _self._map.setMapTypeId('styled_map');
        });

        this._markers = [];
        this._markerset = { };

        this.addSearchBox(mapguiDomId);

        this._currentPosition = false;
        // fit bounds event listener for correct zoom, fitBounds is async, so listen to it
        google.maps.event.addListenerOnce(_self._map, 'bounds_changed', function (event) {
            // remove one zoom level to ensure no marker is on the edge.
            _self._map.setZoom(_self._map.getZoom() - 1);

            if (_self._map.getZoom() > 15) {
                _self._map.setZoom(15);
            }
        });
    };

    MarsoMaps.prototype.fit = function (markers) {
        if (typeof this._map == 'undefined') {
            return false;
        }

        var _self = this,
            markers = markers || this._markers,
            bounds = new google.maps.LatLngBounds();

        for (i = 0; i < markers.length; i++) {
            bounds.extend(markers[i].getPosition());
        }

        // center the map to the geometric center of all markers
        this._map.setCenter(bounds.getCenter());

        // zoom to markers
        this._map.fitBounds(bounds);
    };

    MarsoMaps.prototype.fitToNearest = function (currentPosition, markers) {
        if (!this._currentPosition && typeof currentPosition == 'undefined') {
            // where to fit?
            return false;
        }

        var _self = this,
            markers = markers || this._markers,
            currentPosition = currentPosition || new google.maps.LatLng(_self._currentPosition),
            bounds = new google.maps.LatLngBounds();

        if (!markers.length) {
            // fit what?
            return;
        }

        bounds.extend(currentPosition);

        // find positions in a 100km radius
        var maxDistance = 50000,
            reduced = []; // 50km

        while (!reduced.length && maxDistance < 100000) {
            reduced = markers.filter(function (curr) {
                var cpos = _self.distance({ latitude: currentPosition.lat(), longitude: currentPosition.lng() }, { latitude: curr.position.lat(), longitude: curr.position.lng() });

                return cpos < maxDistance;
            });
            maxDistance += 10000; // add 10kms until we find a near position
        }

        // find 5 nearest within this radius
        var nearest = (reduced.length ? reduced : markers).sort(function (prev, curr) {
            var cpos = _self.distance({ latitude: currentPosition.lat(), longitude: currentPosition.lng() }, { latitude: curr.position.lat(), longitude: curr.position.lng() });
            var ppos = _self.distance({ latitude: currentPosition.lat(), longitude: currentPosition.lng() }, { latitude: prev.position.lat(), longitude: prev.position.lng() });

            return ppos - cpos;
        }).slice(0, 5);

        for (var i = 0; i < nearest.length; i++) {
            bounds.extend(nearest[i].getPosition());
        }

        // center the map to the geometric center of all markers
        this._map.setCenter(bounds.getCenter());

        // zoom to markers
        this._map.fitBounds(bounds);
    };

    MarsoMaps.prototype.geolocate = function (callback) {
        var _self = this;

        // Try HTML5 geolocation.
        google.maps.event.addListenerOnce(this._map, 'idle', function () {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    _self._currentPosition = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };

                    _self.fitToNearest();

                    var currentPositionMarker = new google.maps.Marker({
                        position: _self._currentPosition,
                        map: _self._map,
                        icon: {
                            path: google.maps.SymbolPath.CIRCLE,
                            fillColor: '#4285F4',
                            fillOpacity: 0.8,
                            scale: 8,
                            strokeColor: '#999',
                            strokeWeight: 3,
                            strokeOpacity: 0.6
                        }
                    });
                    currentPositionMarker.addListener('click', function () {
                        _self.infowindow._marker = this;
                        _self.infowindow.setContent('<div class="wrapper"><div class="title"><strong>' + App.t('Your position') + '</strong></div></div>');
                        _self.infowindow.open();
                    });

                    if (typeof callback != 'undefined') {
                        callback(_self._currentPosition);
                    }
                });
            }
        });
    };

    MarsoMaps.staticGeolocate = function (callback) {
        if (typeof callback == 'undefined') {
            return false;
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                callback({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
            });
        }
    };

    MarsoMaps.prototype.setMarkers = function (markers) {
        this.hideMarkers();
        this._markers = markers;
        this.showMarkers();
    };

    MarsoMaps.prototype.getMap = function () {
        return this._map;
    };

    MarsoMaps.prototype.addMarkerset = function (id, set) {
        this._markerset[id] = set;
    };

    /**
     * Load specific markerset to map
     *
     * @param {string} id
     * @returns {undefined}
     */
    MarsoMaps.prototype.loadMarkerset = function (id) {
        if (typeof this._markerset[id] == 'undefined') {
            return false;
        }

        this.setMarkers(this._markerset[id]);
    };

    /**
     * Load all markersets to map
     *
     * @returns {undefined}
     */
    MarsoMaps.prototype.loadMarkersets = function () {
        var markers = [];

        for (var id in this._markerset) {
            if (typeof this._markerset[id] != 'undefined') {
                markers = markers.concat(this._markerset[id]);
            }
        }

        this.setMarkers(markers);
    };

    MarsoMaps.prototype.hideMarkers = function () {
        for (i = 0; i < this._markers.length; i++) {
            this._markers[i].setMap(null);
        }
    };

    MarsoMaps.prototype.showMarkers = function () {
        for (i = 0; i < this._markers.length; i++) {
            this._markers[i].setMap(this._map);
        }
    };

    MarsoMaps.prototype.getMarkerTemplate = function (location, title, isOwnService, infowindowContent) {
        var _self = this,
            marker = new google.maps.Marker({
                position: { lat: location.lat, lng: location.lng },
                title: title,
                icon: isOwnService ? this.marsoIcon : this.partnerIcon
            });

        if (typeof infowindowContent != 'undefined') {
            marker.addListener('click', function () {
                _self.infowindow._marker = this;
                _self.infowindow.setContent(typeof infowindowContent == 'object' ? $(infowindowContent).html() : infowindowContent);
                _self.infowindow.open();
            });
        }

        return marker;
    };

    MarsoMaps.prototype.addSearchBox = function (mapguiDomId) {
        var _self = this,
            mapGui = document.getElementById(mapguiDomId);

        if (!mapGui) {
            return false;
        }

        this._map.controls[google.maps.ControlPosition.TOP_RIGHT].push(mapGui);

        // Create the search box and link it to the UI element.
        var searchInput = document.getElementById('map-search-input');

        if (typeof searchInput == 'undefined' || searchInput === null) {
            return;
        }

        var autocomplete = new google.maps.places.Autocomplete(searchInput, { types: ['geocode'], componentRestrictions: { country: this.country } }),
            autocompleteService = new google.maps.places.AutocompleteService(),
            placesService = new google.maps.places.PlacesService(this._map);

        // Bind the map's bounds (viewport) property to the autocomplete object,
        // so that the autocomplete requests use the current map bounds for the
        // bounds option in the request.
        autocomplete.bindTo('bounds', this._map);

        var marker = null;
        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        autocomplete.addListener('place_changed', function placeChanged (place) {
            var place = place || autocomplete.getPlace();

            if (!place.geometry) {
                if (!place.name.length) {
                    return;
                }

                // user not selected suggestion just hit enter
                // serch for first place based on the input data

                autocompleteService.getPlacePredictions({
                    input: place.name,
                    types: ['geocode'],
                    componentRestrictions: {
                        country: _self.country
                    }
                }, function (predictions, status) {
                    if (status != google.maps.places.PlacesServiceStatus.OK) {
                        return;
                    }

                    // we need the first result only
                    placesService.getDetails({ placeId: predictions[0].place_id }, function (place, status) {
                        if (status !== google.maps.places.PlacesServiceStatus.OK) {
                            return;
                        }

                        placeChanged(place);
                    });
                });

                return;
            }

            if (marker) {
                marker.setMap(null);
                marker = null;
            }

            marker = new google.maps.Marker({
                map: _self._map,
                icon: _self.locationIcon,
                title: place.name,
                position: place.geometry.location
            });

            _self.fitToNearest(place.geometry.location);
        });
    };

    /**
     * Haversine formula
     *
     * @param {object} start { latitude: Number, longitude: Number }
     * @param {object} end { latitude: Number, longitude: Number }
     * @param {object} options { unit: 'km' || 'mile' || 'meter' || 'nmi', threshold: Number }
     * @returns {Number}
     */
    MarsoMaps.prototype.distance = function (start, end, options) {
        // convert to radians
        var toRad = function (num) {
            return num * Math.PI / 180;
        };

        options = options || { unit: 'meter', threshold: 0 };

        var radii = {
            km: 6378.137,
            mile: 3963.19059,
            meter: 6378137
        };

        var R = options.unit in radii ? radii[options.unit] : radii.km;

        var dLat = toRad(end.latitude - start.latitude);
        var dLon = toRad(end.longitude - start.longitude);
        var lat1 = toRad(start.latitude);
        var lat2 = toRad(end.latitude);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        if (options.threshold) {
            return options.threshold > (R * c);
        }

        return R * c;
    };

    App.MarsoMaps = MarsoMaps;
})(App, jQuery);


(function (App, $) {

    // open tread text details
    $('.content-tread .button-more').on('click', function(event) {
        var description = $(event.currentTarget).parents('.description');
        description.addClass('full');
    });
    // close tread text details
    $('.content-tread .button-less').on('click', function(event) {
        var description = $(event.currentTarget).parents('.description');
        description.removeClass('full');
    });

    // filter menus
    var submenus = {};

    $('#manufacturer-treads-filter-menu .submenu').each(
        function(i, value) {
            var key = $(value).data('menu-parent-url');
            submenus[key] = $(value);
        }
    );

    $('#manufacturer-treads-filter-menu .mainmenu .item').on('click', function(event) {
        var url = $(event.currentTarget).data('url');
        if (submenus[url]) {
            event.preventDefault();
            $('#manufacturer-treads-filter-menu .submenu').addClass('invisible');
            submenus[url].removeClass('invisible');
        }
    });

    $('#manufacturer-treads-filter-menu .mainmenu.dropdown').dropdown();
    $('#manufacturer-treads-filter-menu .submenu.dropdown').dropdown();


})(App, jQuery);


(function (App, $) {

    if (!$('#service-site-images').length) {
        return;
    }

    function ServiceSiteSlider() {
        this.items = $('#service-site-images .item');
        this.autoplay = null;
        this.autoplayInterval = 10000;
    }

    ServiceSiteSlider.prototype.init = function() {
        this.items.on('click',function(event) {
            var item = $(event.currentTarget);
            event.preventDefault();
            slider.switchTo(item);
        });

        $('#service-site-header').on('mouseenter', function(event){
            slider.autoplayStop();
        }).on('mouseleave', function(event){
            slider.autoplayStart();
        });

    };

    ServiceSiteSlider.prototype.switch = function() {
        var next, found;
        this.items.each(function(i, item) {
            if (undefined != found) {
                next = $(item);
                return false;
            }
            if ($(item).hasClass('active')) {
                found = i;
            }
        });
        if (next) {
            this.switchTo(next);
        } else {
            this.switchTo(this.items.first());
        }
    };

    ServiceSiteSlider.prototype.switchTo = function(item) {
        var imgUrl = item.attr('href');
        $('#service-site-image').attr('src',imgUrl);
        $('#service-site-image-src').attr('srcset',imgUrl + '.webp');
        this.items.removeClass('active');
        item.addClass('active');
    };

    ServiceSiteSlider.prototype.autoplayStart = function() {
        if (this.autoplay) {
            this.autoplayStop();
        }
        this.autoplay = setInterval(function() {
                slider.switch();
            }, this.autoplayInterval
        );
    };

    ServiceSiteSlider.prototype.autoplayStop = function() {
        clearInterval(this.autoplay);
    };

    var slider = new ServiceSiteSlider();
    slider.init();
    slider.autoplayStart();

})(App, jQuery);

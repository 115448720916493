
(function ($, App) {

    // dot tyres request form
    var productsSection = $('#dot-tyres-products-section');
    var modal = $('#dot-tyres-modal');

    if (0 == modal.length) {
        return;
    }

    function calculateQuantityAndPrice(input)
    {
        var q = input.val();
        var inputData = input.data();

        if (q > inputData.max) {
            q = inputData.max;
            input.val(inputData.max);
        } else if (1 > q) {
            q = 1;
            input.val(1);
        }

        var price = App.Utils.formatCurrency(inputData.unitPrice * q, inputData.currency, inputData.locale);
        $('#product_price').val(price);
    }

    $('#product_quantity').on('change', (e) => {
       var input = $(e.currentTarget);
       calculateQuantityAndPrice(input);
    }).on('input', (e) => {
        var input = $(e.currentTarget);
        calculateQuantityAndPrice(input);
    });

    productsSection.on('click', '.start-request-form', (e) => {
        var button = $(e.currentTarget);
        var i, q;
        var buttonData = button.data();

        var pn = modal.find('#product_name');
        pn.val(buttonData.name);

        var pq = modal.find('#product_quantity');
        q = buttonData.maxQuantity;
        if (4 < q) {
            q = 4;
        }
        pq.val(q);
        pq.data('max', buttonData.maxQuantity);
        pq.data('locale', buttonData.locale);
        pq.data('currency', buttonData.currency);
        pq.data('unit-price', buttonData.unitPrice);

        var ps = modal.find('#product_sku');
        ps.val(buttonData.sku);

        var pup = modal.find('#product_unit_price');
        pup.val(App.Utils.formatCurrency(buttonData.unitPrice, buttonData.currency, buttonData.locale));

        pq.trigger('change');

        modal.modal('show');
    });

})(jQuery, App);

(function ($, App) {
    // request offer form
    var modal = $('#dot-tyres-modal');

    if (0 == modal.length) {
        return;
    }

    var DotTyresOfferForm = function () {
        var self = this;
        this.form = $('#dot-tyres-modal-form');
        this.errorSkeleton = '<div class="error-label ui basic red pointing below prompt label transition visible"></div>';
        this.fields = {
            product_quantity: {
                element: $('#product_quantity'),
                isValid: function (validator, element) {
                    var max = element.data('max');
                    return validator.validate(element.val())
                        .required().minValue(1).maxValue(max)
                        .isValid();
                }
            },
            user_name: {
                element: $('#user_name'),
                isValid: function (validator, element) {
                    return validator.validate(element.val())
                        .required()
                        .isValid();
                }
            },
            user_email: {
                element: $('#user_email'),
                isValid: function (validator, element) {
                    return validator.validate(element.val())
                        .required().email()
                        .isValid();
                }
            },
            user_phone: {
                element: $('#user_phone'),
                isValid: function (validator, element) {
                    var mask = element.data('mask');
                    var format = "" + element.data('mask-human');
                    if (!format.length) {
                        format = mask;
                    }
                    return validator.validate(element.val())
                        .required().mask(mask, App.t('The format must be: %mask%', { '%mask%': format }))
                        .isValid();
                }
            },
            user_message: {
                element: $('#user_message'),
                isValid: function (validator, element) {
                    return validator.validate(element.val())
                        .isValid();
                }
            },
            dot_tyres_privacy_accepted: {
                element: $('#dot_tyres_privacy_accepted'),
                isValid: function (validator, element) {
                    var v ='';
                    if (element.prop('checked')) {
                        v = element.val();
                    }
                    return validator.validate(v)
                        .required()
                        .isValid();
                }
            },
        };

    };

    DotTyresOfferForm.prototype.init = function (validator) {

        var self = this;
        this.validator = validator;

        this.form.on('submit', function(event) {
            event.preventDefault();
            self.submit();
        });

        this.form.find('.submit-button').on('click', function(event) {
            self.form.submit();
        });

        this.form.find('.dimmer').on('click', function(event) {
            var dimmer = $(event.currentTarget);
            dimmer.dimmer('hide');
            if (dimmer.hasClass('success')) {
                modal.modal('hide');
            }
        });

        for (var name in this.fields) {
            var element = this.fields[name].element;
            element.on('change', function(event){
                self.isValidField(event.currentTarget.id, true);
            });
            element.on('focusout', function(event){
                self.isValidField(event.currentTarget.id, true);
            });
            element.on('keyup', function(event){
                if (9 != event.which) {
                    self.isValidField(event.currentTarget.id, true);
                }
            });
        }

    };

    DotTyresOfferForm.prototype.isValidField = function(name, displayError) {
        name = name || undefined;
        displayError = displayError || false;
        var valid = true;
        if (this.fields.hasOwnProperty(name)) {
            if (this.fields[name].hasOwnProperty('isValid')) {
                if (!this.fields[name].isValid(this.validator, this.fields[name].element)) {
                    valid = false;
                }
            }
        } else {
            throw "Form field not exists!";
        }

        if (displayError) {
            var errorLabel = this.fields[name].element.parents('.field').find('.error-label');
            if (errorLabel.length) {
                errorLabel.remove();
            }
            if (!valid) {
                var msg = this.validator.getError();
                var error = this.errorSkeleton;
                this.fields[name].element.parents('.field').append($(error).html(msg));
            }
        }

        return valid;
    };

    DotTyresOfferForm.prototype.isValid = function(displayError) {
        displayError = displayError || false;

        var valid = true;
        for (var name in this.fields) {
            if (!this.isValidField(name, displayError)) {
                valid = false;
            }
        }

        return valid;
    };

    DotTyresOfferForm.prototype.getData = function() {
        var data = {};
        for (var key in this.fields) {
            if (this.fields[key].hasOwnProperty('value')) {
                data[key] = this.fields[key].value();
            } else {
                data[key] = $('#' + key).val();
            }
        }

        data['product_sku'] = $('#product_sku').val();
        data['product_name'] = $('#product_name').val();

        return data;
    };

    DotTyresOfferForm.prototype.reset = function() {
        this.form.trigger('reset');
    };

    DotTyresOfferForm.prototype.submit = function() {
        var self = this;

        if (!self.isValid(true)) {
            return;
        }

        this.form.addClass('loading');

        $.ajax({
            type: "POST",
            url: self.form.attr('action'),
            dataType: "json",
            data: self.getData(),
            success: function(data) {
                self.form.removeClass('loading');
                switch (data.status) {
                    case 'success':
                        self.form.find('.dimmer.success').dimmer('show');
                        var element = self.form.find('.dimmer.success .messages');
                        element.empty();
                        if (data.success.messages) {
                            for ( i = 0, len = data.success.messages.length; i < len; ++i) {
                                element.append('<p>' + data.success.messages[i] + '</p>');
                            }
                        }
                        self.reset();
                        break;
                    case 'error':
                        self.form.find('.dimmer.error').dimmer('show');
                        var element = self.form.find('.dimmer.error .messages');
                        element.empty();
                        if (data.error.messages) {
                            for ( i = 0, len = data.error.messages.length; i < len; ++i) {
                                element.append('<p>' + data.error.messages[i] + '</p>');
                            }
                        }
                        break;
                }
            },
            error: function(data) {
                self.form.removeClass('loading');
            }
        });
    };

    var form = new DotTyresOfferForm();
    form.init(App.Validator);

})(jQuery, App);

(function ($, App) {

    // dot-tyres
    // desktop version
    var mobile = $('html.mobile-layout').length;
    var productsSection = $('#dot-tyres-products-section');

    productsSection.on('click', '#js-reset-filters', function () {
        if (!mobile) {
            dimmable.dimmer('show');
            App.Utils.scrollTo($('.dot-tyres-content'), 0, 500);
        }

        productsSection.load(
            productsSection.data('default-url'),
            { },
            function() {
                $('.ordering-select').dropdown();
                if (!mobile) {
                    setTimeout(function() { dimmable.dimmer('hide'); }, 200);
                }
            }
        );
    });

    if (0 == productsSection.length || mobile) {
        return;
    }

    var dimmable = $('#dot-tyres-products-section-wrapper');
    var ajaxUrl = productsSection.data('url');

    dimmable.dimmer('show');
    productsSection.load(
        productsSection.data('default-url'),
        { },
        function() {
            $('.ordering-select').dropdown();
            setTimeout(function() { dimmable.dimmer('hide'); }, 200);
        }
    );


    productsSection.on('click','.pager > a', function (event) {
        var params = '';
        var link = $(event.currentTarget);
        var data = $('#dot-tyres-products-form').serializeArray();

        event.preventDefault();
        if (link.hasClass('active')) {
            return;
        }

        var url = link.prop('href');
        var found = url.indexOf('?');
        if (-1 !=found) {
            params = url.slice(found);
        }

        App.Utils.scrollTo(productsSection, -50, 500);
        dimmable.dimmer('show');
        productsSection.load(
            ajaxUrl + params,
            data,
            function() {
                $('.ordering-select').dropdown();
                setTimeout(function() { dimmable.dimmer('hide'); }, 200);
            }
        );
    });

    productsSection.on('click','.ordering-select .menu > a', function (event) {
        var params = '';
        var link = $(event.currentTarget);
        var data = $('#dot-tyres-products-form').serializeArray();
        event.preventDefault();

        var url = link.prop('href');
        var found = url.indexOf('?');
        if (-1 !=found) {
            params = url.slice(found);
        }

        App.Utils.scrollTo(productsSection, -50, 500);
        dimmable.dimmer('show');
        productsSection.load(
            ajaxUrl + params,
            data,
            function() {
                $('.ordering-select').dropdown();
                setTimeout(function() { dimmable.dimmer('hide'); }, 200);
            }
        );
    });

    productsSection.on('change', '#dot-tyres-products-form', function(event) {
        var form = $(event.currentTarget);
        var changed = $(event.target);
        var action = form.prop('action');
        var sidebarForm = $('#dot-tyres-products-form');

        if (changed.hasClass('width') || changed.hasClass('profile') || changed.hasClass('diameter')) {
            // remove size
            sidebarForm.find('.sizes').remove();
        }

        var data = form.serializeArray();
        App.Utils.scrollTo(productsSection, -50, 500);
        dimmable.dimmer('show');
        productsSection.load(
            action,
            data,
            function() {
                $('.ordering-select').dropdown();
                setTimeout(function() { dimmable.dimmer('hide'); }, 200);
            }
        );
    });
})(jQuery, App);

(function (App, $) {
    // dot-tyres
    // mobile version
    var mobile = $('html.mobile-layout').length;
    var productsSection = $('#dot-tyres-products-section');

    if (0 == productsSection.length || !mobile) {
        return;
    }

    var dimmable = $('#dot-tyres-products-section-wrapper');
    var ajaxUrl = productsSection.data('url');

    dimmable.dimmer('show');
    productsSection.load(
        productsSection.data('default-url'),
        {},
        function() {
            $('.ordering-select').dropdown();
            setTimeout(function() { dimmable.dimmer('hide'); }, 200);
        }
    );

    productsSection.on('click','.pager > a', function (event) {
        var params = '';
        var link = $(event.currentTarget);
        var data = $('#dot-tyres-products-form').serializeArray();

        event.preventDefault();
        if (link.hasClass('active')) {
            return;
        }

        var url = link.prop('href');
        var found = url.indexOf('?');
        if (-1 !=found) {
            params = url.slice(found);
        }
        App.Utils.scrollTo(productsSection, -50, 500);
        dimmable.dimmer('show');
        productsSection.load(
            ajaxUrl + params,
            data,
            function() {
                $('.ordering-select').dropdown();
                setTimeout(function() { dimmable.dimmer('hide'); }, 200);
            }
        );
    });

    productsSection.on('click','.ordering-select .menu > a', function (event) {
        var params = '';
        var link = $(event.currentTarget);
        var data = $('#dot-tyres-products-form').serializeArray();
        event.preventDefault();

        var url = link.prop('href');
        var found = url.indexOf('?');
        if (-1 !=found) {
            params = url.slice(found);
        }

        App.Utils.scrollTo(productsSection, -50, 500);
        dimmable.dimmer('show');
        productsSection.load(
            ajaxUrl + params,
            data,
            function() {
                $('.ordering-select').dropdown();
                setTimeout(function() { dimmable.dimmer('hide'); }, 200);
            }
        );
    });

    productsSection.on('change', '#dot-tyres-products-form', function(event) {
        var changed = $(event.target);
        var sidebarForm = $('#dot-tyres-products-form');

        if (changed.hasClass('width') || changed.hasClass('profile') || changed.hasClass('diameter')) {
            // remove size
            sidebarForm.find('.sizes').remove();
        }
        var refreshUrl = sidebarForm.data('refresh-url');
        var formData = sidebarForm.serializeArray();
        var button = $('.detailed-search-panel .show-results');
        var ids = sidebarForm.data('id');
        if (ids) {
            ids = ids.split(',');
            formData.push({name: 'id', value: ids});
        }

        sidebarForm.find('.search-status').addClass('visible');
        button.addClass('loading');
        $.ajax({
            url: refreshUrl,
            data: formData,
            dataType: 'json',
            method: 'post'
        }).done(function(data){
            button.removeClass('loading');
            if (data.success) {
                button.find('.found-counter').text(data.results.total);
                refreshOptions(data.results.form_elements, data.results.options);
            }
        });
    });

    productsSection.on('click', '.show-results', function(event) {
        var form = $('#dot-tyres-products-form');
        var action = form.prop('action');
        var data = form.serializeArray();

        App.Utils.scrollTo(productsSection, -50, 500);
        dimmable.dimmer('show');
        productsSection.load(
            action,
            data,
            function() {
                $('.ordering-select').dropdown();
                setTimeout(function() { dimmable.dimmer('hide'); }, 200);
            }
        );
    });

    productsSection.on('click', '.show-filter-panel', function(event) {
        $('.floated-form-panel').addClass('active');
        $('.search-status').addClass('open');
        App.Utils.scrollTo(productsSection, -50, 800);
    });

    productsSection.on('click', '.hide-filter-panel', function(event) {
        $('.floated-form-panel').removeClass('active');
        $('.search-status').removeClass('open');
    });

    function refreshCheckbox(key, values) {
        if (!Array.isArray(values)) {
            values = [ values ];
        }
        $('#dot-tyres-products-form').find('.checkbox-field .'+key).each(function(index, elem){
            elem = $(elem);
            if (-1 == $.inArray(elem.prop('value'), values)) {
                elem.prop('disabled', true);
                elem.parents('.checkbox-field').addClass('disabled');
            } else {
                elem.prop('disabled', false);
                elem.parents('.checkbox-field').removeClass('disabled');
            }
        });
    }

    function refreshSelect(key, values) {
        if (!Array.isArray(values)) {
            values = [ values ];
        }
        $('#dot-tyres-products-form').find('.select-field .'+key+' option').each(function(index, elem){
            elem = $(elem);
            var value = elem.prop('value');
            if (value) {
                if (-1 == $.inArray(value, values)) {
                    elem.addClass('invisible');
                } else {
                    elem.removeClass('invisible');
                }
            }
        });
    }

    function refreshOptions(elements, options) {
        $.each(elements, function(_, key) {
            var value = [];
            switch (key) {
                case 'manufacturer_id':
                case 'season':
                case 'car_type':
                case 'run_flat':
                case 'reinforced_version':
                case 'rim_fringe_protector':
                case 'fuel_efficiency':
                case 'wet_grip_class':
                case 'noise_measurement_class':
                case 'machine_type':
                case 'tyre_type':
                    if (options[key]) {
                        value = options[key];
                    }
                    refreshCheckbox(key, value);
                    break;
                case 'width':
                case 'profile':
                case 'diameter':
                case 'holes':
                case 'bolt_pattern':
                case 'wheel_offset':
                    if (options[key]) {
                        value = options[key];
                    }
                    refreshSelect(key, value);
                    break;
                default: break;
            }
        });
    }

})(App, jQuery);

(function ($) {
    $('#edit-profile').click(function () {
        var form = $(this).parents('form');

        form.find('input[disabled]')
            .attr('disabled', false)
            .attr('data-originally-disabled', true);

        form.find('input[type="email"]').attr('readonly', true);

        form.find('.buttons.invisible').removeClass('invisible');
        $(this).addClass('invisible');
    });

    $('#cancel-edit-profile').click(function () {
        var form = $(this).parents('form');

        form.find('input[data-originally-disabled]').attr('disabled', true);

        form.find('.buttons:not(.invisible)').addClass('invisible');
        $(this)
            .parent('div')
            .parent('div')
            .find('#edit-profile')
            .removeClass('invisible');
    });

    var addressEditModal = $('#address-edit-modal');
    addressEditModal.find('.actions .approve.button').click(function () {
        addressEditModal.find('form').submit();
    });

    $('#add-address-button').click(function () {
        addressEditModal.find('.header').text($(this).data('header-text'));

        addressEditModal.find('.content input').each(function (i, el) {
            if (el.id != 'address__token') {
                $(el).val('');
            }
        });

        addressEditModal.modal({ closable: false }).modal('show');
    });

    $('.edit-address-button').click(function () {
        var data = $(this).data('address-data');

        addressEditModal.find('.header').text($(this).data('header-text'));

        $.each(data, function (i, el) {
            var input = addressEditModal.find('.content input#address_' + i);
            if (input.length) {
                input.val(el);
            }
        });

        addressEditModal.modal({ closable: false }).modal('show');
    });
})(jQuery);

(function ($) {
    // orders list table
    var modal = $('#order-view-modal'),
        defaultModalContent = modal.find('.content').html();

    function loadModal (url) {
        $.ajax({
            type: 'GET',
            dataType: "html",
            url: url
        }).done(function (html) {
            modal.find('.form-block').html(html);
            modal.modal('refresh');
        });
    }

    // EVENTS

    // show loading modal and load the data url
    $('.account-page .orders-table tbody tr').on('click', function (event) {
        // click one order row
        modal
            .modal('setting', {
                onHidden: function () {
                    modal.find('.form-block').html(defaultModalContent);
                },
                closable: false
            })
            .modal('show');
        var target = $(event.currentTarget);
        var url = target.data('url');
        loadModal(url);
    });
})(jQuery);

(function ($, App) {
    'use strict';

    $(function() {
        if (typeof App.CarForm === 'undefined') {
            return;
        }

        App.CarForm.parentInit = App.CarForm.init;

        App.CarForm.initSpecialSizeFields = function() {
            var specialSizeFields = {
                summer_tyre_size: {
                    element: App.CarForm.form.find('#summer_tyre_size'),
                    isValid: function() {
                        return true;
                    }
                },
                winter_tyre_size: {
                    element: App.CarForm.form.find('#winter_tyre_size'),
                    isValid: function() {
                        return true;
                    }
                },
                front_tyre_size: {
                    element: App.CarForm.form.find('#front_tyre_size'),
                    isValid: function() {
                        return true;
                    }
                },
                rear_tyre_size: {
                    element: App.CarForm.form.find('#rear_tyre_size'),
                    isValid: function() {
                        return true;
                    }
                },
                summer_tyre_front: {
                    element: App.CarForm.form.find('#summer_tyre_front'),
                    isValid: function() {
                        return true;
                    }
                },
                summer_tyre_rear: {
                    element: App.CarForm.form.find('#summer_tyre_rear'),
                    isValid: function() {
                        return true;
                    }
                },
                winter_tyre_front: {
                    element: App.CarForm.form.find('#winter_tyre_front'),
                    isValid: function() {
                        return true;
                    }
                },
                winter_tyre_rear: {
                    element: App.CarForm.form.find('#winter_tyre_rear'),
                    isValid: function() {
                        return true;
                    }
                }
            };

            this.fields = Object.assign({}, this.fields, specialSizeFields);

            App.CarForm.form.find('#same_winter_summer_sizes').prop(
                'checked',
                App.CarForm.form.find('#summer_tyre_front_size').val() === App.CarForm.form.find('#winter_tyre_front_size').val()
                && App.CarForm.form.find('#summer_tyre_rear_size').val() === App.CarForm.form.find('#winter_tyre_rear_size').val()
            );
            App.CarForm.form.find('#same_winter_summer_sizes').trigger('change');

            App.CarForm.form.find('#same_rear_front_sizes').prop(
                'checked',
                App.CarForm.form.find('#summer_tyre_front_size').val() === App.CarForm.form.find('#summer_tyre_rear_size').val()
                && App.CarForm.form.find('#winter_tyre_front_size').val() === App.CarForm.form.find('#winter_tyre_rear_size').val()
            );
            App.CarForm.form.find('#same_rear_front_sizes').trigger('change');

            var winterSummerSame = $('#same_winter_summer_sizes').is(':checked'),
                rearFrontSame = $('#same_rear_front_sizes').is(':checked');

            if (winterSummerSame && rearFrontSame) {
                var size = App.CarForm.form.find('#summer_tyre_front_size').val();

                App.CarForm.form.find('#tyre_size').parent('.ui.dropdown')
                    .dropdown('set value', size)
                    .dropdown('set text', size);

                for (var key in specialSizeFields) {
                    if (specialSizeFields.hasOwnProperty(key)) {
                        specialSizeFields[key].element.parent('.ui.dropdown')
                            .dropdown('set value', size)
                            .dropdown('set text', size);
                    }
                }
            } else if (!winterSummerSame && rearFrontSame) {
                var summersize = App.CarForm.form.find('#summer_tyre_front_size').val(),
                    wintersize = App.CarForm.form.find('#winter_tyre_front_size').val();

                specialSizeFields.summer_tyre_size.element.parent('.ui.dropdown')
                    .dropdown('set value', summersize)
                    .dropdown('set text', summersize);
                specialSizeFields.summer_tyre_front.element.parent('.ui.dropdown')
                    .dropdown('set value', summersize)
                    .dropdown('set text', summersize);
                specialSizeFields.summer_tyre_rear.element.parent('.ui.dropdown')
                    .dropdown('set value', summersize)
                    .dropdown('set text', summersize);

                specialSizeFields.winter_tyre_size.element.parent('.ui.dropdown')
                    .dropdown('set value', wintersize)
                    .dropdown('set text', wintersize);
                specialSizeFields.winter_tyre_front.element.parent('.ui.dropdown')
                    .dropdown('set value', wintersize)
                    .dropdown('set text', wintersize);
                specialSizeFields.winter_tyre_rear.element.parent('.ui.dropdown')
                    .dropdown('set value', wintersize)
                    .dropdown('set text', wintersize);
            } else if (winterSummerSame && !rearFrontSame) {
                var frontsize = App.CarForm.form.find('#summer_tyre_front_size').val(),
                    rearsize = App.CarForm.form.find('#summer_tyre_rear_size').val();

                specialSizeFields.front_tyre_size.element.parent('.ui.dropdown')
                    .dropdown('set value', frontsize)
                    .dropdown('set text', frontsize);
                specialSizeFields.rear_tyre_size.element.parent('.ui.dropdown')
                    .dropdown('set value', rearsize)
                    .dropdown('set text', rearsize);

                specialSizeFields.summer_tyre_front.element.parent('.ui.dropdown')
                    .dropdown('set value', frontsize)
                    .dropdown('set text', frontsize);
                specialSizeFields.summer_tyre_rear.element.parent('.ui.dropdown')
                    .dropdown('set value', rearsize)
                    .dropdown('set text', rearsize);
                specialSizeFields.winter_tyre_front.element.parent('.ui.dropdown')
                    .dropdown('set value', frontsize)
                    .dropdown('set text', frontsize);
                specialSizeFields.winter_tyre_rear.element.parent('.ui.dropdown')
                    .dropdown('set value', rearsize)
                    .dropdown('set text', rearsize);
            } else {
                var summerFrontSize = App.CarForm.form.find('#summer_tyre_front_size').val(),
                    summerRearSize = App.CarForm.form.find('#summer_tyre_rear_size').val(),
                    winterFrontSize = App.CarForm.form.find('#winter_tyre_front_size').val(),
                    winterRearSize = App.CarForm.form.find('#winter_tyre_rear_size').val();

                specialSizeFields.summer_tyre_front.element.parent('.ui.dropdown')
                    .dropdown('set value', summerFrontSize)
                    .dropdown('set text', summerFrontSize);
                specialSizeFields.summer_tyre_rear.element.parent('.ui.dropdown')
                    .dropdown('set value', summerRearSize)
                    .dropdown('set text', summerRearSize);
                specialSizeFields.winter_tyre_front.element.parent('.ui.dropdown')
                    .dropdown('set value', winterFrontSize)
                    .dropdown('set text', winterFrontSize);
                specialSizeFields.winter_tyre_rear.element.parent('.ui.dropdown')
                    .dropdown('set value', winterRearSize)
                    .dropdown('set text', winterRearSize);
            }

            return specialSizeFields;
        };

        App.CarForm.init = function(selector) {
            App.CarForm.parentInit(selector);

            var self = this,
                specialSizeFields = this.initSpecialSizeFields();

            self.fields.tyre_size.element.on('change', function() {
                for (var key in specialSizeFields) {
                    if (specialSizeFields.hasOwnProperty(key)) {
                        specialSizeFields[key].element.val($(this).val());
                    }
                }

                self.fields.summer_tyre_front_size.element.val($(this).val());
                self.fields.summer_tyre_rear_size.element.val($(this).val());
                self.fields.winter_tyre_front_size.element.val($(this).val());
                self.fields.winter_tyre_rear_size.element.val($(this).val());
            });

            self.fields.summer_tyre_size.element.on('change', function() {
                self.fields.summer_tyre_front_size.element.val($(this).val());
                self.fields.summer_tyre_rear_size.element.val($(this).val());

                self.fields.summer_tyre_front.element.val($(this).val());
                self.fields.summer_tyre_rear.element.val($(this).val());
            });

            self.fields.winter_tyre_size.element.on('change', function() {
                self.fields.winter_tyre_front_size.element.val($(this).val());
                self.fields.winter_tyre_rear_size.element.val($(this).val());

                self.fields.winter_tyre_front.element.val($(this).val());
                self.fields.winter_tyre_rear.element.val($(this).val());
            });

            self.fields.front_tyre_size.element.on('change', function() {
                self.fields.summer_tyre_front_size.element.val($(this).val());
                self.fields.summer_tyre_front.element.val($(this).val());
                self.fields.winter_tyre_front_size.element.val($(this).val());
                self.fields.winter_tyre_front.element.val($(this).val());
            });

            self.fields.rear_tyre_size.element.on('change', function() {
                self.fields.summer_tyre_rear_size.element.val($(this).val());
                self.fields.summer_tyre_rear.element.val($(this).val());
                self.fields.winter_tyre_rear_size.element.val($(this).val());
                self.fields.winter_tyre_rear.element.val($(this).val());
            });

            self.fields.summer_tyre_front.element.on('change', function() {
                self.fields.summer_tyre_front_size.element.val($(this).val());
            });
            self.fields.winter_tyre_front.element.on('change', function() {
                self.fields.winter_tyre_front_size.element.val($(this).val());
            });
            self.fields.summer_tyre_rear.element.on('change', function() {
                self.fields.summer_tyre_rear_size.element.val($(this).val());
            });
            self.fields.winter_tyre_rear.element.on('change', function() {
                self.fields.winter_tyre_rear_size.element.val($(this).val());
            });

            var tyreSizeFields = Object.assign({}, specialSizeFields, { tyre_size: self.fields.tyre_size });
            for (var key in tyreSizeFields) {
                if (tyreSizeFields.hasOwnProperty(key)) {
                    tyreSizeFields[key].element.parent('.ui.dropdown').dropdown({
                        apiSettings: {
                            url: '/ajax/product-search/tyre-size-autocomplete?search={query}'
                        },
                        searchOnFocus: true,
                        minCharacters: 0,
                        fields: {
                            remoteValues : 'results', // grouping for api results
                            name         : 'name',   // displayed dropdown text
                            value        : 'name'   // actual dropdown value
                        },
                        onChange: function(value, text, $choice) {
                            for (var key in tyreSizeFields) {
                                if (tyreSizeFields.hasOwnProperty(key)) {
                                    tyreSizeFields[key].element.parent('.ui.dropdown')
                                        .dropdown('set value', tyreSizeFields[key].element.val())
                                        .dropdown('set text', tyreSizeFields[key].element.val());
                                }
                            }
                        }
                    });
                }
            }
        };

        var modal = $('#cars-edit-modal');

        $('#add-car-button').click(function () {
            modal.find('.content input').each(function (i, el) {
                $(el).val('');
            });
            modal.find('.alcar-car-image').removeClass('ready');
            modal.find('.alcar-car-image .status-image').empty().addClass('invisible');
            modal.find('.alcar-car-image .status-basic').removeClass('invisible');
            modal.modal({ closable: false }).modal('show');
            App.CarForm.init();
        });

        modal.find('.actions .approve.button').click(function () {
            if (App.CarForm.isValid()) {
                modal.find('form').submit();
                return true;
            }

            return false;
        });

        modal.find('.new_image-field input').change(function(e) {
            var label = $(this).next('label'),
                fileName = e.target.value.split( '\\' ).pop();

            if (fileName) {
                label.addClass('file-selected').find('span').text(fileName);
            } else {
                label.removeClass('file-selected').find('span').text(label.data('original-text'));
            }
        });

        $('.edit-car-button').click(function () {
            var data = $(this).data('car-data');

            modal.find('.header').text($(this).data('header-text'));

            $.each(data, function (i, el) {
                var input = modal.find('.content input#' + i);
                if (input.length) {
                    input.val(el);
                }
                if (i == 'image' && el.length) {
                    var img = new Image();
                    img.alt = 'car-image';
                    img.src = el;
                    img.className = 'ui centered image'; // don't use fluid, because the alcar image is low res

                    modal.find('.alcar-car-image').addClass('ready');
                    modal.find('.alcar-car-image .status-basic').addClass('invisible');
                    modal.find('.alcar-car-image .status-image')
                        .empty()
                        .removeClass('invisible')
                        .append(img);
                } else if (i == 'image' && !el.length) {
                    modal.find('.alcar-car-image').removeClass('ready');
                    modal.find('.alcar-car-image .status-image').empty().addClass('invisible');
                    modal.find('.alcar-car-image .status-basic').removeClass('invisible');
                }
            });

            modal.modal({ closable: false }).modal('show');

            App.CarForm.init();
        });

        var sizeSelectorEvents = function() {
            var winterSummerSame = $('#same_winter_summer_sizes').is(':checked'),
                rearFrontSame = $('#same_rear_front_sizes').is(':checked'),
                tyreSizeBlock = $('#tyre_size_block'),
                winterSummerSizesBlock = $('#same_winter_summer_sizes_block'),
                rearFrontSizesBlock = $('#same_rear_front_sizes_block'),
                allTyreSizesBlock = $('#all_tyre_sizes_block');

            if (winterSummerSame && rearFrontSame) {
                tyreSizeBlock.removeClass('invisible');
                winterSummerSizesBlock.addClass('invisible');
                rearFrontSizesBlock.addClass('invisible');
                allTyreSizesBlock.addClass('invisible');
            } else if (!winterSummerSame && rearFrontSame) {
                tyreSizeBlock.addClass('invisible');
                winterSummerSizesBlock.removeClass('invisible');
                rearFrontSizesBlock.addClass('invisible');
                allTyreSizesBlock.addClass('invisible');
            } else if (winterSummerSame && !rearFrontSame) {
                tyreSizeBlock.addClass('invisible');
                winterSummerSizesBlock.addClass('invisible');
                rearFrontSizesBlock.removeClass('invisible');
                allTyreSizesBlock.addClass('invisible');
            } else if (!winterSummerSame && !rearFrontSame) {
                tyreSizeBlock.addClass('invisible');
                winterSummerSizesBlock.addClass('invisible');
                rearFrontSizesBlock.addClass('invisible');
                allTyreSizesBlock.removeClass('invisible');
            }
        };

        $('#same_winter_summer_sizes').on('change', sizeSelectorEvents);
        $('#same_rear_front_sizes').on('change', sizeSelectorEvents);
    });
})(jQuery, App);

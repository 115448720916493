var ProductSidebarSearchJS = function() {

(function (App, $) {

    var sidebarForm = $('.product-search-sidebar-form');

    function refreshCheckbox(key, values) {
        if (!Array.isArray(values)) {
            values = [ values ];
        }
        sidebarForm.find('.checkbox-field .'+key).each(function(index, elem){
            elem = $(elem);
            if (-1 == $.inArray(elem.prop('value'), values)) {
                elem.prop('disabled', true);
                elem.parents('.checkbox-field').addClass('disabled');
            } else {
                elem.prop('disabled', false);
                elem.parents('.checkbox-field').removeClass('disabled');
            }
        });
    }

    function refreshSelect(key, values) {
        if (!Array.isArray(values)) {
            values = [ values ];
        }
        sidebarForm.find('.select-field .'+key+' option').each(function(index, elem){
            elem = $(elem);
            var value = elem.prop('value');
            if (value) {
                if (-1 == $.inArray(value, values)) {
                    elem.addClass('invisible');
                } else {
                    elem.removeClass('invisible');
                }
            }
        });
    }

    function refreshOptions(elements, options) {
        $.each(elements, function(_, key) {
            var value = [];
            switch (key) {
                case 'manufacturer_id':
                case 'season':
                case 'car_type':
                case 'run_flat':
                case 'reinforced_version':
                case 'rim_fringe_protector':
                case 'fuel_efficiency':
                case 'wet_grip_class':
                case 'noise_measurement_class':
                case 'machine_type':
                case 'tyre_type':
                case 'application_notation':
                    if (options[key]) {
                        value = options[key];
                    }
                    refreshCheckbox(key, value);
                    break;
                case 'width':
                case 'profile':
                case 'diameter':
                case 'holes':
                case 'bolt_pattern':
                case 'center_bore':
                case 'wheel_offset':
                    if (options[key]) {
                        value = options[key];
                    }
                    refreshSelect(key, value);
                    break;
                default: break;
            }
        });
    }

    sidebarForm.on('change', function(event) {
        var changed = $(event.target);
        if (changed.hasClass('width') || changed.hasClass('profile') || changed.hasClass('diameter')) {
            // remove size
            sidebarForm.find('.sizes').remove();
        }
        var refreshUrl = sidebarForm.data('refresh-url');
        var formData = sidebarForm.serializeArray();
        var button = $('.detailed-search-panel .show-results');
        sidebarForm.find('.search-status').addClass('visible');
        button.addClass('loading');
        $.ajax({
            url: refreshUrl,
            data: formData,
            dataType: 'json',
            method: 'post'
        }).done(function(data){
            button.removeClass('loading');
            if (data.success) {
                button.find('.found-counter').text(data.results.total);
                refreshOptions(data.results.form_elements, data.results.options);
            }
        });
    });

    $('.detailed-search-panel .show-results').on('click', function(event) {
        sidebarForm.submit();
    });

    $('.detailed-search-panel .show-filter-panel').on('click', function(event) {
        $('.detailed-search-panel .floated-form-panel').addClass('active');
        $('.detailed-search-panel .search-status').addClass('open');
        App.Utils.scrollTo($('html'), 0, 800);
    });
    $('.detailed-search-panel .hide-filter-panel').on('click', function(event) {
        $('.detailed-search-panel .floated-form-panel').removeClass('active');
        $('.detailed-search-panel .search-status').removeClass('open');
    });

})(App, jQuery);

};

export default ProductSidebarSearchJS;

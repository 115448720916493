// step choose car
(function ($, App) {
    if (!$('#step-choose-car').length) {
        return;
    }

    function CarForm() {
        this.alcarProducts = {};
        this.productsLoaded = false;
    }

    CarForm.prototype.init = function () {

        $('#choose-car-form-submit').on('click', function(event){
            $('#choose-car-form').submit();
        });

        $('#choose-car-form').on('submit', function(event){
            return self.send();
        });

        $('#brand_id').dropdown({ fullTextSearch: true });
        $('#type_id').dropdown({ fullTextSearch: true });
        $('#model_id').dropdown({ fullTextSearch: true });

        $.fn.form.settings.templates.prompt = function (errors) {
            return $('<div/>')
                .addClass('ui basic red pointing below prompt label')
                .html(errors[0])
                ;
        };

        this.loadBrands();

        $('#brand_id').on('change',function(event) {
            if ($('#brand_id').val()) {
                self.stepBack('brand');
                self.stepForward('brand');
            }
        });

        $('#type_id').on('change',function(event) {
            if ($('#type_id').val()) {
                self.stepBack('type');
                self.stepForward('type');
            }
        });

        $('#model_id').on('change',function(event) {
            if ($('#model_id').val()) {
                self.stepBack('model');
                self.stepForward('model');
            }
        });

    };

    CarForm.prototype.send = function() {
        if (!self.validate()) {
            // not valid exit
            return false;
        }
        return true;
    };

    CarForm.prototype.validate = function() {
        var data = {};
        data.brandId = parseInt($('#brand_id').val());
        if (data.brandId) {
            data.brand = $('#brand_id option:selected').text();
            $('#brand').val(data.brand);
        }
        data.typeId = parseInt($('#type_id').val());
        if (data.typeId) {
            data.type = $('#type_id option:selected').text();
            $('#type').val(data.type);
        }
        data.modelId = parseInt($('#model_id').val());
        if (data.modelId) {
            data.model = $('#model_id option:selected').text();
            $('#model').val(data.model);
        }
        data.image = $('#image').val();

        if (data.brandId && data.brand &&
            data.typeId && data.type &&
            data.modelId && data.model &&
            data.image) {
            return true;
        }
        return false;
    };

    CarForm.prototype.stepForward = function(stepName) {
        switch (stepName) {
            case 'brand':
                self.loadTypes();
                break;
            case 'type':
                self.loadModels();
                break;
            case 'model':
                self.loadImage();
                break;
            default: break;
        }
    };

    CarForm.prototype.stepBack = function(stepName) {
        switch (stepName) {
            case 'brand':
                $('#type_id').empty();
                $('#type_id').dropdown('restore defaults');
            //break;
            case 'type':
                $('#model_id').empty();
                $('#model_id').dropdown('restore defaults');
            //break;
            case 'model':
                $('.alcar-car-image .status-image').empty();
                $('.alcar-car-image').removeClass('ready');
                self.productsLoaded = false;
                self.alcarProducts = [];
            //break;
            default: break;
        }
    };

    CarForm.prototype.loadImage = function() {
        $('.alcar-car-image .status-image').empty();
        $('.alcar-car-image').removeClass('ready');
        $('.alcar-car-image').addClass('waiting');

        var modelCode = $('#model_id').val();
        if (!modelCode) {
            $('.alcar-car-image').removeClass('waiting');
            return;
        }
        App.Alcar.getAlcarProducts(modelCode, function(status, alcarProducts) {
            if (!status) {
                $('.alcar-car-image').removeClass('waiting');
                return;
            }
            var keys = Object.keys(alcarProducts);
            for (var i = 0, len = keys.length; i < len; ++i) {
                var model = alcarProducts[keys[i]];
                if (model.ImageOnCarBinary) {
                    $('.alcar-car-image').addClass('ready');
                    $('.alcar-car-image .status-image').append(
                        $('<img>', {
                            class: 'ui fluid image',
                            src: model.ImageOnCarBinary
                        })
                    );
                    $('#image').val(model.ImageOnCarBinary);
                    break;
                }
            }
            $('.alcar-car-image').removeClass('waiting');
        });
    };

    CarForm.prototype.loadModels = function() {
        var brandCode = $('#brand_id').val();
        var typeCode = $('#type_id').val();
        if (!brandCode || !typeCode) {
            // if missing brand or type code, unable to load anything
            return;
        }
        $('.alcar-model-field .input').addClass('loading');
        App.Alcar.getModels(brandCode, typeCode, function(status, models){
            if (status) {
                $.each(models, function (i, item) {
                    $('#model_id').append('<option value="'+item.ModelCode+'">'+item.ModelName+'</option>');
                });
            }
            $('.alcar-model-field .input').removeClass('loading');
            $('#model_id').dropdown('restore defaults');
        });
    };

    CarForm.prototype.loadTypes = function() {
        var brandCode = $('#brand_id').val();
        if (!brandCode) {
            // if not brand code, unable to load anything
            return;
        }
        $('.alcar-type-field .input').addClass('loading');
        App.Alcar.getTypes(brandCode, function(status, types){
            if (status) {
                $.each(types, function (i, item) {
                    $('#type_id').append('<option value="'+item.TypeCode+'">'+item.TypeText+'</option>');
                });
            }
            $('.alcar-type-field .input').removeClass('loading');
            $('#type_id').dropdown('restore defaults');
        });
    };

    CarForm.prototype.loadBrands = function() {
        $('.alcar-brand-field .input').addClass('loading');
        App.Alcar.getBrands(function(status, brands){
            $('.alcar-brand-field .input').removeClass('loading');
            if (status) {
                $.each(brands, function (i, item) {
                    $('#brand_id').append('<option value="'+item.BrandCode+'">'+item.BrandText+'</option>');
                });
            }
        });
    };

    var self = new CarForm();
    self.init();

})(jQuery, App);

// step choose rim
(function ($,App) {
    if (!$('#step-choose-rim').length) {
        return;
    }

    $('#diameter').on('change', function(event){
        var select = $(event.currentTarget);
        var size = select.val();
        $('#alcal_rim_id').val('');
        $('#image_on_car').val('');
        $('#choose-rim-form-rims .select-rim-button.selected').removeClass('selected');
        $('#choose-rim-form-rims .rim').not('.hidden').transition('scale');
        $("#choose-rim-form-rims .rim[data-size='" + size + "']").transition('scale');
    }).change();

    $('#choose-rim-form-rims .select-rim-button').on('click',
        function(event) {
            var b = $(event.currentTarget);
            var image = b.data('image-on-car');
            var arid = b.data('alcar-rim-id');
            if (image) {
                $('#image-on-car').attr('src', image);
            }
            $('#alcar_rim_id').val(arid);
            $('#image_on_car').val(image);
            $('#choose-rim-form-rims .select-rim-button.selected').removeClass('selected');
            b.addClass('selected');
    });

    $('#choose-rim-form-submit').on('click', function(event){
        var selected = $('#choose-rim-form-rims .select-rim-button.selected');
        var arid = $('#alcar_rim_id').val();
        if (arid && selected.length) {
            $('#choose-rim-form').submit();
        } else {
            App.Messages.add(App.t("Please choose a rim first!"),'error');
        }
    });

})(jQuery, App);

// step choose tyre
(function ($,App) {
    if (!$('#step-choose-tyre').length) {
        return;
    }

    function filter() {
        var season = $('#season').val();
        var size = $('#size').val();

        $('#tyre_id').val('');
        $('#choose-tyre-form-tyres .select-tyre-button.selected').removeClass('selected');
        $('#choose-tyre-form-tyres .select-tyre-button').not('.hidden').transition('scale');
        $("#choose-tyre-form-tyres .select-tyre-button[data-season='" + season + "'][data-size='" + size + "']").transition('scale');
    }

    $('#season,#size').on('change', function(event) {
        filter();
    });

    filter();

    $('#choose-tyre-form-tyres .select-tyre-button').on('click',
        function(event) {
            var b = $(event.currentTarget);
            var tid = b.data('tyre-id');
            $('#tyre_id').val(tid);
            $('#choose-tyre-form-tyres .select-tyre-button.selected').removeClass('selected');
            b.addClass('selected');
            return false;
    });

    $('#choose-tyre-form-submit').on('click', function(event){
        var selected = $('#choose-tyre-form-tyres .select-tyre-button.selected');
        var tid = $('#tyre_id').val();
        if (tid && selected.length) {
            $('#choose-tyre-form').submit();
        } else {
            App.Messages.add(App.t("Please choose a tyre first!"),'error');
        }
    });


})(jQuery, App);
